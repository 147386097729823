import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Form,
  Input,
  Select,
  Table,
  Switch,
  Tooltip,
  Tabs,
} from "antd";
import PhoneInput from "react-phone-number-input";

import "react-phone-number-input/style.css";
import { EditOutlined, DeleteOutlined } from "@mui/icons-material";
import ChatIcon from "@mui/icons-material/Chat";
import { toast } from "react-toastify";
import { get_api, post_api } from "../helper/Api";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../components/modals/ConfirmationModal";

const { Option } = Select;
const { TabPane } = Tabs;

const Customer = () => {
  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [locations, setLocations] = useState([]);

  const [error, setError] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [editingCustomer, setEditingCustomer] = useState(null);
  const [loading, setLoading] = useState(false);
  const [nameValue, setNameValue] = useState("");

  const [activeTab, setActiveTab] = useState("customer");
  const [allUnknownCustomer, setAllUnknownCustomer] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [selected, setSelected] = useState(null);

  const handleNameChange = (e) => {
    const { value } = e.target;
    if (value && value[0] === " ") {
      setNameValue(value.trimStart());
    } else {
      setNameValue(value);
    }
  };

  const createCustomer = async (values) => {
    try {
      setLoading(true);
      if (values.whatsapp_number && values.whatsapp_number.startsWith("+")) {
        values.whatsapp_number = values.whatsapp_number.substring(1);
      }
      const locationString = values.location.toString();
      const nameString = values.name;
      const whatsappNumber = values.whatsapp_number;
      let payload = {
        ...values,
        name: nameString.trim(),
        location: locationString.trim(),
        whatsapp_number: whatsappNumber.trim(),
      };

      if (!payload.name) {
        toast.error("Please provide a name");
        return "";
      }
      if (!payload.location) {
        toast.error("Please provide a location");
        return "";
      }
      if (!payload.whatsapp_number) {
        toast.error("Please provide a location");
        return "";
      }
      const response = await post_api(`/customer/create-new-customer`, payload);

      toast.success(response.data.message);
      await getAllCustomer();

      setModalVisible(false);
    } catch (err) {
      console.error(err);
      toast.error(err.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const getAllLocation = async () => {
    try {
      setLoading(true); // Start loading

      const response = await get_api(`/get-all-active-restaurants`);

      setLocations(response.data.data);
      // setError(null);
    } catch (err) {
      console.error(err);
      toast.error("Failed to fetch locations. Please try again.");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const getAllCustomer = async () => {
    try {
      setLoading(true); // Start loading
      const response = await get_api(`/customer/get-all-customers`);

      setCustomers(
        response.data.data.map((item, i) => ({
          ...item,
          sno: i + 1,
          loc_name: locations
            .filter((obj) => {
              let currentItem = item.location
                .split(",")
                .map((item) => parseInt(item));
              console.log({ currentItem });
              return currentItem.includes(obj.id);
            })
            .map((loc) => {
              return loc.location;
            })
            ?.toString(),
        }))
      );
      // setError(null);
    } catch (err) {
      console.error(err);
      setError("Failed to fetch customers. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const getAllUnknownCustomer = async () => {
    try {
      const response = await get_api(
        `/unknown-customer/get-all-unknown-customers`
      );
      setAllUnknownCustomer(response.data.data);

      // console.log({ response });
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const updateCustomer = async (values) => {
    try {
      setLoading(true); // Start loading

      if (values.whatsapp_number && values.whatsapp_number.startsWith("+")) {
        values.whatsapp_number = values.whatsapp_number.substring(1);
      }

      const locationString = values.location.toString();

      let payload = {
        ...values,
        location: locationString,
      };

      const response = await post_api(
        `/customer/update-customer/${editingCustomer.id}`,
        payload
      );

      toast.success(response.data.message);
      getAllCustomer();
    } catch (err) {
      console.error(err);
      toast.error(err.response.data.message);
      // setError("Failed to update customers. Please try again.");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const switchChange = async (checked, record) => {
    console.log(checked, record);
    try {
      setLoading(true); // Start loading
      const newStatus = !record.is_active ? 1 : 0;

      const response = await post_api(
        `/customer/toggle-customer/${record.id}/${newStatus}`,
        {}
      );

      toast.success(response.data.message);
      getAllCustomer();
    } catch (error) {
      toast.error(error.response.data.message);
      console.error("Error toggling location:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllLocation();
  }, []);

  useEffect(() => {
    if (locations.length > 0) {
      getAllUnknownCustomer();
      getAllCustomer();
    }
  }, [locations]);

  const handleAddUser = () => {
    setModalVisible(true);
  };

  const handleCancel = () => {
    setModalVisible(false);
    setEditingCustomer(null);
    form.resetFields();
  };

  const handleEdit = (customer) => {
    console.log("customer", customer);
    setEditingCustomer(customer);
    setModalVisible(true);

    let parsedLocation;
    try {
      parsedLocation = JSON.parse(customer.location);
    } catch (e) {
      parsedLocation = customer.location.split(",").map((loc) => parseInt(loc));
    }

    form.setFieldsValue({
      ...customer,
      location: parsedLocation,
      whatsapp_number: `+${customer.whatsapp_number}`,
    });
  };

  const onFinish = async (values) => {
    if (editingCustomer) {
      await updateCustomer(values);
    } else {
      await createCustomer(values);
    }
    form.resetFields();
    setModalVisible(false);
    setEditingCustomer(null);
  };

  const handleDelete = async (customer) => {
    try {
      setLoading(true);
      const response = await post_api(
        `/customer/delete-customer/${customer.id}`
      );

      if (response.data.status) {
        toast.success(response.data.message);
        getAllCustomer();
      }
      setLoading(false);
      console.log(response);
    } catch (e) {
      setLoading(false);
      toast.error(e.response.data.message);
      console.log(e);
    }
    console.log("Delete Customer", customer.id);
  };

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  const columns = [
    {
      title: "S.No.",
      dataIndex: "sno",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Location",
      dataIndex: "loc_name",
      ellipsis: true,
      render: (text) => (
        <Tooltip placement="topLeft" title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Number",
      dataIndex: "whatsapp_number",
    },

    // {
    //   title: "Status",
    //   dataIndex: "is_active",
    //   key: "status",
    //   render: (checked, record) => (
    //     <Switch
    //       checkedChildren="Active"
    //       unCheckedChildren="Inactive"
    //       checked={checked === true}
    //       className="ml-2"
    //       onChange={(checked) => switchChange(checked, record)}
    //     />
    //   ),
    // },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <>
          <span className="text-[#1890ff] cursor-pointer" title="Chat">
            <ChatIcon
              style={{ marginRight: 8 }}
              onClick={() => navigate(`/chat-list/${record.whatsapp_number}`)}
            />
          </span>
          <span className="text-[#1890ff] cursor-pointer" title="Edit">
            <EditOutlined
              style={{ marginRight: 8 }}
              onClick={() => handleEdit(record)}
            />
          </span>
          <span className="cursor-pointer" title="Delete">
            <DeleteOutlined
              style={{ marginRight: 8, color: "#ff0505" }}
              // onClick={() => handleDelete(record)}
              onClick={() => {
                setSelected(record);
                setConfirmModal(true);
              }}
            />
          </span>
        </>
      ),
    },
  ];

  const columns2 = [
    {
      title: "S.No.",
      dataIndex: "sno",
    },
    {
      title: "Number",
      dataIndex: "whatsapp_number",
    },
    {
      title: "Thread Id",
      dataIndex: "thread_id",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <>
          <span className="text-[#1890ff] cursor-pointer" title="Chat">
            <ChatIcon
              style={{ marginRight: 8 }}
              onClick={() => navigate(`/chat-list/${record.whatsapp_number}`)}
            />
          </span>
        </>
      ),
    },
  ];

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  console.log({ allUnknownCustomer });

  return (
    <div className="container mx-auto mt-8 p-4 bg-gray-100 shadow-md rounded-md">
      <ConfirmationModal
        isModalOpen={confirmModal}
        setIsModalOpen={setConfirmModal}
        handleSubmit={() => {
          handleDelete(selected);
        }}
        handleCancel1={() => {
          setSelected(null);
        }}
      />
      <div>
        <div className="flex items-center justify-end">
          <Button type="primary" onClick={handleAddUser}>
            Add Customer
          </Button>
        </div>
      </div>
      <Modal
        title={editingCustomer ? "Edit Customer" : "Add Customer"}
        visible={modalVisible}
        onCancel={handleCancel}
        confirmLoading={loading}
        maskClosable={false}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={() => form.submit()}>
            Submit
          </Button>,
        ]}
      >
        <Form
          {...layout}
          form={form}
          name="control-hooks"
          onFinish={onFinish}
          style={{
            maxWidth: 600,
          }}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[
              { required: true, message: "Please enter restaurant name" },
              {
                pattern: /^[^\s]/,
                message: "The name cannot start with a space",
              },
            ]}
          >
            <Input
              value={nameValue}
              onChange={handleNameChange}
              placeholder="Enter name"
            />
          </Form.Item>

          <Form.Item name="email" label="Email" rules={[{ type: "email" }]}>
            <Input placeholder="Enter email" />
          </Form.Item>
          <Form.Item
            name="whatsapp_number"
            label="Number"
            rules={[
              {
                required: true,
                message: "Phone number must be more than 6 digits.",
                min: 7,
              },
            ]}
            className="mb-4"
          >
            <PhoneInput
              international
              limitMaxLength={10}
              defaultCountry={editingCustomer ? "" : "GB"}
              placeholder="Enter phone number"
              value={form.getFieldValue("whatsapp_number") || ""}
              onChange={(value) =>
                form.setFieldsValue({ whatsapp_number: value })
              }
              className=" custom-dropdown w-full p-2 rounded border border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
            />
          </Form.Item>
          <Form.Item
            name="location"
            label="Location"
            rules={[
              {
                required: true,
                message: "Please select your location!",
              },
            ]}
          >
            <Select
              placeholder="Select locations"
              mode="multiple"
              loading={loading}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              dropdownRender={(menu) => (
                <div style={{ maxHeight: "200px", overflow: "auto" }}>
                  {menu}
                </div>
              )}
            >
              {locations.map((location) => (
                <Option key={location.id} value={location.id}>
                  {`${location.name} - ${location.location}`}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>

      {error && <div className="text-red-600 text-center mt-2">{error}</div>}

      <Tabs defaultActiveKey="customer" size="small" onChange={handleTabChange}>
        <TabPane tab="Customer" key="customer">
          <Table
            dataSource={customers
              .filter((item) => item.is_deleted === false)
              .map((item, index) => ({
                ...item,
                sno: index + 1,
                // loc_name: item.location,
                loc_name: locations
                  .filter((obj) => {
                    let currentItem = item.location
                      .split(",")
                      .map((item) => parseInt(item));
                    return currentItem.includes(obj.id);
                  })
                  .map((loc) => {
                    return `${loc.location}`;
                  })
                  ?.toString(),
              }))}
            columns={columns}
            rowKey="sno"
            pagination={{ pageSize: 10 }}
            loading={loading}
            className="mt-4"
          />
        </TabPane>
        <TabPane tab="Unknown Customer" key="unknown-customer">
          <Table
            dataSource={allUnknownCustomer.map((item, index) => ({
              ...item,
              sno: index + 1,
            }))}
            columns={columns2}
            rowKey="sno"
            pagination={{ pageSize: 10 }}
            loading={loading}
            className="mt-4"
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Customer;
