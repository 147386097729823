import { Modal } from "antd";
import React from "react";

const ConfirmationModal = ({
  isModalOpen,
  setIsModalOpen,
  handleSubmit,
  handleCancel1,
}) => {
  const handleOk = () => {
    handleSubmit();
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    handleCancel1();
    setIsModalOpen(false);
  };

  return (
    <Modal
      title="Confirmation Modal"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <p>Are you sure you want to perform this operation?</p>
    </Modal>
  );
};

export default ConfirmationModal;
