import { Menu, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";
import { get_api } from "../helper/Api";
import SingleChatMessage from "../components/SingleChatMessage";
import { MdKeyboardBackspace } from "react-icons/md";

const ChatList = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [allMessages, setAllMessages] = useState([]);
  const messagesEndRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [allChats, setAllChats] = useState([]);
  const [currentChat, setCurrentChat] = useState(null);
  const [chatType, setChatType] = useState(0);
  const [locations, setLocations] = useState([]);
  const [currentCustomer, setCurrentCustomer] = useState({});
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [allMessages]);

  const getAllLocation = async () => {
    try {
      setLoading(true); // Start loading

      const response = await get_api(`/get-all-active-restaurants`);

      setLocations(response.data.data);
      // setError(null);
    } catch (err) {
      console.error(err);
      // toast.error("Failed to fetch locations. Please try again.");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const getAllChats = async (id) => {
    const response = await get_api(`/chat/get-all-chats-by-mobile/${id}`);
    setAllChats(response.data?.data);
  };

  const getCustomer = async (id) => {
    try {
      const response = await get_api(`/customer/get-customer-by-contact/${id}`);
      setCurrentCustomer(response.data?.data);
    } catch (error) {
      setCurrentCustomer({});
    }
  };

  useEffect(() => {
    if (params?.id) {
      // getRepData(params.id);
      getAllLocation();
      getAllChats(params.id);
      getCustomer(params.id);
    }
  }, [params]);

  const getMessages = async (ticket_id) => {
    try {
      setLoading(true);
      const response = await get_api(
        `/chat-message/get-messages-by-chat-id/${ticket_id ? ticket_id : 0}`
      );

      if (response.data) {
        setAllMessages(response.data);
      }
      setLoading(false);
    } catch (error) {
      console.log({ error });
      setLoading(false);
    }
  };
  useEffect(() => {
    if (currentChat) {
      getMessages(currentChat.id);
    }
  }, [currentChat]);

  const handleChatType = (value) => {
    setChatType(value);
  };

  const getLocationName = (id) => {
    const location = locations.find((item) => item.id === parseInt(id));
    return location.fullname;
  };

  console.log({ currentChat, locations, currentCustomer });

  return (
    <div className="flex flex-col h-full ">
      <div className="flex justify-between items-center">
        <div className="flex justify-start items-center gap-x-3">
          <MdKeyboardBackspace
            size={32}
            className="text-[#1976D2] cursor-pointer"
            onClick={() => navigate(-1)}
          />
          <div className="flex flex-row items-center rounded-xl p-2">
            <div className="flex items-center justify-center h-10 w-10 bg-indigo-200 rounded-full">
              {currentCustomer && currentCustomer.name
                ? currentCustomer.name
                    .split(" ")
                    .map((part) => part.charAt(0))
                    .join("")
                : "UC"}
            </div>
            <div className="ml-2 flex flex-col flex-grow ">
              <p className="text-black font-semibold mb-1 leading-none capitalize">
                {currentCustomer && currentCustomer.name
                  ? currentCustomer.name
                  : "Unknown"}
              </p>
              <div className="text-gray-500 leading-none flex items-center gap-x-3 text-xs">
                <span>{params.id}</span>
              </div>
            </div>
            {/* <div className="ml-2 flex flex-col flex-grow ">
            <p className="text-black font-semibold mb-1 leading-none">
              {params.id}
            </p>
          </div> */}
          </div>
        </div>
        <div className="">
          <Menu as="div" className="relative inline-block text-left">
            <div className="relative">
              <Menu.Button className="text-left text-blue-700 border border-blue-700 font-medium rounded-lg text-sm pl-2 pr-10 py-2">
                {chatType === 0 ? "Resolved Chat" : "Active Chat"}
                <ExpandMoreIcon
                  className="absolute right-1 top-2 h-3 w-3 text-blue-700 hover:text-blue-800 cursor-pointer"
                  aria-hidden="true"
                />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                <div className="px-1 py-1 ">
                  <Menu.Item className="mb-1">
                    {({ active }) => (
                      <button
                        className={`${
                          chatType === 1
                            ? "bg-green-500 text-white"
                            : "text-gray-900"
                        } group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-green-500 hover:text-white`}
                        onClick={() => {
                          handleChatType(1);
                          setCurrentChat(null);
                        }}
                      >
                        Active
                      </button>
                    )}
                  </Menu.Item>

                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`${
                          chatType === 0
                            ? "bg-green-500 text-white"
                            : "text-gray-900"
                        } group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-green-500 hover:text-white`}
                        onClick={() => {
                          handleChatType(0);
                          setCurrentChat(null);
                        }}
                      >
                        Resolved
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>

      <div className="flex flex-1 text-gray-800 ">
        <div className="flex flex-row w-full overflow-x-hidden h-full">
          <div className="flex flex-col py-3 px-6 w-3/12 flex-shrink-0 border-r-2 border-gray-400 overflow-auto h-[76vh] bg-white">
            {/* <div className="flex justify-between items-center mb-5">
              <div>
                <Menu as="div" className="relative inline-block text-left">
                  <div className="relative">
                    <Menu.Button className="text-left text-blue-700 border border-blue-700 font-medium rounded-lg text-sm pl-2 pr-10 py-2">
                      {chatType === 0 ? "Resolved Chat" : "Active Chat"}
                      <ExpandMoreIcon
                        className="absolute right-1 top-2 h-3 w-3 text-blue-700 hover:text-blue-800 cursor-pointer"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute left-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                      <div className="px-1 py-1 ">
                        <Menu.Item className="mb-1">
                          {({ active }) => (
                            <button
                              className={`${
                                chatType === 1
                                  ? "bg-green-500 text-white"
                                  : "text-gray-900"
                              } group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-green-500 hover:text-white`}
                              onClick={() => handleChatType(1)}
                            >
                              Active
                            </button>
                          )}
                        </Menu.Item>

                        <Menu.Item>
                          {({ active }) => (
                            <button
                              className={`${
                                chatType === 0
                                  ? "bg-green-500 text-white"
                                  : "text-gray-900"
                              } group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-green-500 hover:text-white`}
                              onClick={() => handleChatType(0)}
                            >
                              Resolved
                            </button>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
            <hr className="w-full " /> */}
            <div className="flex flex-col">
              <div className="flex flex-row items-center justify-between text-xs">
                <span className="font-bold">
                  {chatType === 1
                    ? "Active Conversations"
                    : "Resolved Conversations"}
                </span>
                <div className="flex items-center justify-center bg-green-500 text-white h-6 w-6 rounded-full">
                  <span className="text-sm">
                    {
                      allChats.filter(
                        (item) =>
                          item.is_active === (chatType === 0 ? false : true)
                      ).length
                    }
                  </span>
                </div>
              </div>
              <div className="flex flex-col space-y-1 mt-4 cursor-pointer">
                {allChats &&
                  allChats
                    .filter(
                      (item) =>
                        item.is_active === (chatType === 0 ? false : true)
                    )
                    .map((ticket, i) => (
                      <div
                        key={i}
                        className={`bg-white rounded-xl border border-gray-400 hover:border-l-4 hover:border-green-500 ${
                          currentChat?.id === ticket.id
                            ? "border-l-4 border-green-500"
                            : ""
                        }`}
                        // onClick={() => handleTicketClick(ticket)}
                      >
                        <div
                          className="flex flex-row items-center rounded-xl p-2"
                          onClick={() => {
                            setCurrentChat(ticket);
                          }}
                        >
                          <div className="flex items-center justify-center h-10 w-10 bg-indigo-200 rounded-full">
                            {ticket.type
                              ? ticket?.type
                                  .split(" ")
                                  .map((part) => part.charAt(0))
                                  .join("")
                              : "IC"}
                          </div>
                          <div className=" flex flex-col pl-2">
                            {/* Make the name clickable */}
                            <button
                              className="text-black text-xs font-semibold mb-1 leading-none text-left"
                              onClick={() => {
                                // setUserData(ticket.customer_data);
                                setCurrentChat(ticket);
                              }}
                            >
                              {ticket.type ? ticket?.type : "Initial Chat"}
                            </button>
                            <span className="text-xs">
                              {moment(ticket.createdAt).format(
                                "DD/MM/YY hh:mm A"
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
              </div>
            </div>
          </div>
          {!loading && currentChat ? (
            <div className="flex flex-col flex-auto px-6 py-3">
              <div className="flex justify-between items-center">
                <div className="flex flex-row items-center rounded-xl p-2">
                  {/* <div className="flex items-center justify-center h-10 w-10 bg-indigo-200 rounded-full">
                    AY
                  </div> */}
                  <div className="ml-2 flex flex-col flex-grow ">
                    <p className="text-black font-semibold mb-1 leading-none capitalize">
                      {/* {currentChat.location
                        ? getLocationName(currentChat?.location)
                        : "N/A"} */}
                      {currentCustomer && currentCustomer.name
                        ? currentCustomer.name
                        : "Unknown"}
                    </p>
                    <div className="text-gray-500 leading-none flex items-center gap-x-3 text-xs">
                      <span>
                        Created At:{" "}
                        {moment(currentChat.createdAt).format(
                          "Do MMM,YY HH:mm"
                        )}
                      </span>
                      <span>|</span>
                      <span>
                        Resolved At:{" "}
                        {currentChat.is_active === false
                          ? moment(currentChat.updatedAt).format(
                              "Do MMM,YY HH:mm"
                            )
                          : "N/A"}
                      </span>
                      <span>|</span>
                      <span className="capitalize">
                        Location:{" "}
                        {currentChat.location
                          ? getLocationName(currentChat?.location)
                          : "N/A"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <hr className="w-full " />

              <div className="flex flex-col flex-auto flex-shrink-0 rounded-2xl bg-gray-100 h-[300px] p-4 mt-4">
                <div className="flex flex-col overflow-x-auto mb-4">
                  <div className="flex flex-col">
                    <div className="grid grid-cols-12 gap-y-2">
                      {allMessages?.map((item, i) => (
                        <SingleChatMessage
                          userData={{ mobile: params.id, ...currentCustomer }}
                          item={item}
                          i={i}
                        />
                      ))}
                      <div ref={messagesEndRef} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="w-full flex flex-col justify-center items-center">
              <h1 className="text-xl">No Chats Available</h1>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatList;
