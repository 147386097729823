import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Input, Button, Table, Space, Modal, Form, Tabs } from "antd";
import { EditOutlined } from "@mui/icons-material";
import axios from "axios";
import { Switch } from "antd";
import { get_api, post_api } from "../../helper/Api";
import ConfirmationModal from "../../components/modals/ConfirmationModal";

const { TabPane } = Tabs;

export default function DataUpload() {
  const [restaurantData, setRestaurantData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editId, setEditId] = useState(null);
  const [form] = Form.useForm();
  const [nameValue, setNameValue] = useState("");
  const [locationValue, setLocationValue] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);
  const [selected, setSelected] = useState(null);

  const handleNameChange = (e) => {
    const { value } = e.target;
    if (value && value === " ") {
      setNameValue(value.trimStart());
    } else {
      setNameValue(value.trimStart());
    }
  };

  const handleLocationChange = (e) => {
    const { value } = e.target;
    if (value && value[0] === " ") {
      setLocationValue(value.trimStart());
    } else {
      setLocationValue(value);
    }
  };

  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "sno",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status, record) => (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          checked={status === true}
          // onChange={() => handleDelete(record.id, record.status)}
          onChange={() => {
            setSelected({ id: record.id, status: record.status });
            setConfirmModal(true);
          }}
          className="ml-2"
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <span className="text-[#1890ff] cursor-pointer " title="Edit">
          <EditOutlined
            style={{ marginRight: 8, textDecoration: "none" }}
            onClick={() => handleEdit(record)}
          />
        </span>
      ),
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await get_api(`/get-restaurantname`);

      if (response.data.status) {
        const ar = response.data.data.map((element, index) => ({
          ...element,
          sno: index + 1,
          name: capitalizeFirstLetter(element.name),
          location: capitalizeFirstLetter(element.location),
        }));
        setRestaurantData(ar);
      }
    } catch (error) {
      console.error("Error fetching data", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    try {
      setLoading(true);

      // Trim the input values
      let payload = {
        name: values.name.trim().toLowerCase(),
        location: values.location.trim().toLowerCase(),
      };
      if (!payload.name) {
        toast.error("Please provide a name");
        return "";
      }
      if (!payload.location) {
        toast.error("Please provide a location");
        return "";
      }

      const response = await post_api(`/create-restaurent`, payload);

      if (response?.data?.status) {
        toast.success("Restaurant submitted successfully");
        fetchData();
        form.resetFields();
        setAddModalVisible(false);
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      console.error("Error submitting form", error);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (record) => {
    setEditId(record.id);
    form.setFieldsValue(record);
    setEditModalVisible(true);
  };

  const handleEditSubmit = async (values) => {
    try {
      setLoading(true);
      let payload = {
        name: values.name.trim().toLowerCase(),
        location: values.location.trim().toLowerCase(),
      };

      if (!payload.name) {
        toast.error("Please provide a name");
        return "";
      }
      if (!payload.location) {
        toast.error("Please provide a location");
        return "";
      }

      const response = await post_api(`/update-restaurent/${editId}`, payload);

      form.resetFields();
      toast.success("Restaurant updated successfully");
      fetchData();
      setEditModalVisible(false);
    } catch (error) {
      console.error("Error updating record", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id, status) => {
    try {
      setLoading(true);
      const statusValue = status ? 1 : 0;
      const response = await get_api(
        `/delete-restaurant/${id}?status=${statusValue}`
      );

      if (response?.data?.status) {
        toast.success(response?.data?.message);
        fetchData();
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      toast.error(error.response?.data?.message);
      console.error("Error deleting restaurant:", error);
    } finally {
      setLoading(false);
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleAddModalCancel = () => {
    setAddModalVisible(false);
    form.resetFields();
  };

  const handleEditModalCancel = () => {
    setEditModalVisible(false);
    form.resetFields();
  };

  // const handleTabChange = (key) => {
  //   setActiveTab(key);
  // };

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
  return (
    <>
      <ConfirmationModal
        isModalOpen={confirmModal}
        setIsModalOpen={setConfirmModal}
        handleSubmit={() => handleDelete(selected.id, selected.status)}
        handleCancel1={() => setSelected(null)}
      />
      <div className="container mx-auto mt-8 p-4 bg-gray-100 shadow-md rounded-md ">
        <div className="flex items-end justify-end">
          <Button type="primary" onClick={() => setAddModalVisible(true)}>
            Add Restaurant
          </Button>
        </div>

        <Modal
          title="Add Restaurant"
          visible={addModalVisible}
          onCancel={handleAddModalCancel}
          onOk={form.submit}
          confirmLoading={loading}
          maskClosable={false}
        >
          <Form
            form={form}
            onFinish={handleSubmit}
            {...layout}
            style={{
              maxWidth: 600,
            }}
          >
            <Form.Item
              label="Restaurant Name: "
              name="name"
              rules={[
                { required: true, message: "Please enter restaurant name" },
                {
                  pattern: /^[^\s]/,
                  message: "The name cannot start with a space",
                },
              ]}
            >
              <Input
                value={nameValue}
                onChange={handleNameChange}
                placeholder="Enter name"
              />
            </Form.Item>
            <Form.Item
              label="Location: "
              name="location"
              rules={[
                { required: true, message: "Please enter location" },
                {
                  pattern: /^[^\s]/,
                  message: "The location cannot start with a space",
                },
              ]}
            >
              <Input
                value={locationValue}
                onChange={handleLocationChange}
                placeholder="Enter Location "
              />
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          title="Edit Restaurant"
          visible={editModalVisible}
          onCancel={handleEditModalCancel}
          onOk={form.submit}
          confirmLoading={loading}
          maskClosable={false}
        >
          <Form
            {...layout}
            form={form}
            onFinish={handleEditSubmit}
            style={{
              maxWidth: 600,
            }}
          >
            <Form.Item
              label="Restaurant Name"
              name="name"
              rules={[
                { required: true, message: "Please enter restaurant name" },
                {
                  pattern: /^[^\s]/,
                  message: "The name cannot start with a space",
                },
              ]}
            >
              <Input value={nameValue} onChange={handleNameChange} />
            </Form.Item>
            <Form.Item
              label="Location"
              name="location"
              rules={[
                { required: true, message: "Please enter location" },
                {
                  pattern: /^[^\s]/,
                  message: "The location cannot start with a space",
                },
              ]}
            >
              <Input value={locationValue} onChange={handleLocationChange} />
            </Form.Item>
          </Form>
        </Modal>

        {/* <Table
          dataSource={restaurantData}
          columns={columns}
          rowKey="id"
          loading={loading}
          pagination={{ pageSize: 10 }}
          className="mt-4"
        /> */}
        <Tabs
          defaultActiveKey="active_restaurant"
          size="small"
          // onChange={handleTabChange}
        >
          <TabPane tab="Active Restaurant" key="active_restaurant">
            <Table
              dataSource={restaurantData
                .filter((item) => item.status === true)
                .map((item, index) => ({ ...item, sno: index + 1 }))}
              columns={columns}
              loading={loading}
              locale={{ emptyText: "No Restaurant Found" }}
            />
          </TabPane>
          <TabPane tab="Inactive Restaurant" key="inactive_restaurant">
            <Table
              dataSource={restaurantData
                .filter((item) => item.status === false)
                .map((item, index) => ({ ...item, sno: index + 1 }))}
              columns={columns}
              loading={loading}
              locale={{ emptyText: "No Restaurant Found" }}
            />
          </TabPane>
        </Tabs>
      </div>
    </>
  );
}
