import { Menu, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import axios from "axios";
import SingleMsg from "./SingleMsg";
import moment from "moment";
import { get_api } from "../helper/Api";

import { BsThreeDotsVertical } from "react-icons/bs";
import EscalateTicketModal from "./Esclatedmodal";

const Agent = () => {
  const params = useParams();
  const [currentAgentData, setCurrentAgentData] = useState(null);
  const [ticketType, setTicketType] = useState();

  const [allTickets, setAllTickets] = useState([]);
  const [userData, setUserData] = useState(null);
  const [currentTicket, setCurrentTicket] = useState(null);
  const [allMessages, setAllMessages] = useState([]);
  const messagesEndRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [escalateTicketModal, setEscalateTicketModal] = useState(false);
  const [allManagers, setAllManagers] = useState([]);
  const [locations, setLocations] = useState([]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [allMessages]);

  const getRepData = async (id) => {
    try {
      const response = await get_api(`/reps/get-rep-by-id/${id}`);
      if (response.data.status) {
        setCurrentAgentData(response.data.data);
        setTicketType(() => {
          if (response.data.data?.role === "manager") {
            return "escalated";
          } else {
            return "active";
          }
        });
      }
      // setLocations(response.data.data);
    } catch (error) {
      console.log({ error });
    }
  };

  const getAllLocation = async () => {
    try {
      setLoading(true); // Start loading

      const response = await get_api(`/get-all-active-restaurants`);

      setLocations(response.data.data);
      // setError(null);
    } catch (err) {
      console.error(err);
      // toast.error("Failed to fetch locations. Please try again.");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const getAllManagers = async (location_id) => {
    try {
      const response = await get_api(
        `/reps/get-all-managers-by-location/${location_id ? location_id : 0}`
      );
      if (response.data.status) {
        setAllManagers(response.data.data);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    if (currentTicket) {
      getMessages(currentTicket.chat_id);
      getAllManagers(currentTicket.location);
    }
  }, [currentTicket]);

  const getTicketsRep = async (id) => {
    const response = await get_api(`/ticket/get-tickets-by-rep-id/${id}`);
    setAllTickets(response.data?.data);
  };

  useEffect(() => {
    if (params?.id) {
      getRepData(params.id);
      getTicketsRep(params.id);
      getAllLocation();
    }
  }, [params]);

  const handleTicketType = (type) => {
    setTicketType(type);
    setCurrentTicket(null);
    setUserData(null);
  };

  const getMessages = async (ticket_id) => {
    try {
      setLoading(true);
      const response = await get_api(
        `/chat-message/get-messages-by-chat-id/${ticket_id ? ticket_id : 0}`
      );

      if (response.data) {
        setAllMessages(response.data);
      }
      setLoading(false);
    } catch (error) {
      console.log({ error });
      setLoading(false);
    }
  };
  useEffect(() => {
    if (currentTicket) {
      getMessages(currentTicket.chat_id);
    }
  }, [currentTicket]);

  console.log({ currentAgentData });

  const getLocationName = (id) => {
    const location = locations.find((item) => item.id === parseInt(id));
    return location.fullname;
  };

  return (
    <div className="flex flex-col h-full">
      <EscalateTicketModal
        isOpen={escalateTicketModal}
        setIsOpen={setEscalateTicketModal}
        currentTicket={currentTicket}
        allManagers={allManagers}
        refetchData={() => {
          // socket.emit("escalated-ticket", {
          //   id: parseInt(data.parent_reps),
          //   ticketId: currentTicket?.id,
          // });
          getTicketsRep(params.id);
          // setUserData(null);
          setCurrentTicket(null);
        }}
      />
      <div>
        <div className="flex flex-row items-center rounded-xl p-2">
          <div className="flex items-center justify-center h-10 w-10 bg-indigo-200 rounded-full">
            {currentAgentData?.name
              .split(" ")
              .map((part) => part.charAt(0))
              .join("")}{" "}
          </div>
          <div className="ml-2 flex flex-col flex-grow ">
            <p className="text-black font-semibold mb-1 leading-none">
              {currentAgentData?.name}
            </p>
            {/* <p className="text-gray-500 mb-0 leading-none ">
                  Last Seen at 07:15 PM
                </p> */}
          </div>
        </div>
      </div>
      <div className="flex flex-1 antialiased text-gray-800">
        <div className="flex flex-row w-full overflow-x-hidden">
          <div className="flex flex-col py-3 px-6 w-3/12 bg-white flex-shrink-0 border-r-2 border-gray-400">
            <div className="flex justify-between items-center mb-5">
              <div>
                <Menu as="div" className="relative inline-block text-left">
                  <div className="relative">
                    <Menu.Button className="text-left text-blue-700 border border-blue-700 font-medium rounded-lg text-sm pl-2 pr-10 py-2">
                      {/* Select Ticket Type */}
                      {ticketType === "active" || ticketType === "escalated"
                        ? "Active Ticket"
                        : "Resolved Ticket"}
                      <ExpandMoreIcon
                        className="absolute right-1 top-2 h-3 w-3 text-blue-700 hover:text-blue-800 cursor-pointer"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute left-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                      <div className="px-1 py-1 ">
                        {currentAgentData?.role !== "manager" && (
                          <Menu.Item className="mb-1">
                            {({ active }) => (
                              <button
                                className={`${
                                  ticketType === "active"
                                    ? "bg-green-500 text-white"
                                    : "text-gray-900"
                                } group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-green-500 hover:text-white`}
                                onClick={() => handleTicketType("active")}
                              >
                                Active
                              </button>
                            )}
                          </Menu.Item>
                        )}

                        {currentAgentData?.role === "manager" && (
                          <Menu.Item className="mb-1">
                            {({ active }) => (
                              <button
                                className={`${
                                  ticketType === "escalated"
                                    ? "bg-green-500 text-white"
                                    : "text-gray-900"
                                } group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-green-500 hover:text-white`}
                                onClick={() => handleTicketType("escalated")}
                              >
                                Active
                              </button>
                            )}
                          </Menu.Item>
                        )}

                        <Menu.Item>
                          {({ active }) => (
                            <button
                              className={`${
                                ticketType === "resolved"
                                  ? "bg-green-500 text-white"
                                  : "text-gray-900"
                              } group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-green-500 hover:text-white`}
                              onClick={() => handleTicketType("resolved")}
                            >
                              Resolved
                            </button>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
              {/* <div className="">
                <IoIosNotifications size={20} />
              </div> */}
            </div>
            <hr className="w-full " />
            <div className="flex flex-col mt-5">
              <div className="flex flex-row items-center justify-between text-xs">
                <span className="font-bold">
                  {ticketType === "active"
                    ? "Active Conversations"
                    : ticketType === "resolved"
                    ? "Resolved Conversations"
                    : "Active Conversations"}
                </span>
                <div className="flex items-center justify-center bg-green-500 text-white h-6 w-6 rounded-full">
                  <span className="text-sm">
                    {
                      allTickets.filter((item) => item.status === ticketType)
                        .length
                    }
                  </span>
                </div>
              </div>
              <div className="flex flex-col space-y-1 mt-4 cursor-pointer">
                {allTickets &&
                  // currentTicket &&
                  allTickets
                    ?.filter((item) => item.status === ticketType)
                    .map((ticket, i) => (
                      <div
                        key={i}
                        className={`bg-white rounded-xl border border-gray-400 hover:border-l-4 hover:border-green-500 ${
                          currentTicket?.id === ticket.id
                            ? "border-l-4 border-green-500"
                            : ""
                        }`}
                        // onClick={() => handleTicketClick(ticket)}
                      >
                        <div
                          className="flex flex-row items-center rounded-xl p-2"
                          onClick={() => {
                            setUserData(ticket.customer_data);
                            setCurrentTicket(ticket);
                          }}
                        >
                          <div className="flex items-center justify-center h-10 w-10 bg-indigo-200 rounded-full">
                            {ticket?.customer_data?.name
                              .split(" ")
                              .map((part) => part.charAt(0))
                              .join("")}{" "}
                          </div>
                          <div className=" flex flex-col pl-2">
                            {/* Make the name clickable */}
                            <button
                              className="text-black font-semibold mb-1 leading-none"
                              // onClick={() => {
                              //   setUserData(ticket.customer_data);
                              //   setCurrentTicket(ticket);
                              // }}
                            >
                              {ticket?.customer_data?.name}
                            </button>
                            <span className="text-xs">
                              {moment(ticket.createdAt).format(
                                "DD/MM/YY hh:mm A"
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
              </div>
            </div>
          </div>
          {!loading && currentTicket ? (
            <div
              className="flex flex-col flex-auto px-6 py-3"
              // style={{ maxHeight: "96%", overflowY: "hidden" }}
            >
              <div className="flex justify-between items-center">
                <div className="flex flex-row items-center rounded-xl p-2">
                  <div className="flex items-center justify-center h-10 w-10 bg-indigo-200 rounded-full">
                    {userData?.name
                      .split(" ")
                      .map((part) => part.charAt(0))
                      .join("")}{" "}
                  </div>
                  <div className="ml-2 flex flex-col flex-grow ">
                    <p className="text-black font-semibold mb-1 leading-none">
                      {userData?.name}
                    </p>
                    <div className="text-gray-500 leading-none flex items-center gap-x-3 text-xs">
                      <span>
                        Created:{" "}
                        {moment(currentTicket.createdAt).format(
                          "DD/MM/YY hh:mm A"
                        )}
                      </span>
                      <span>|</span>
                      <span>
                        Escalated:{" "}
                        {currentTicket.escalated_time
                          ? moment(currentTicket.escalated_time).format(
                              "DD/MM/YY hh:mm A"
                            )
                          : "N/A"}
                      </span>
                      <span>|</span>
                      <span>
                        Resolved:{" "}
                        {currentTicket.resolved_time
                          ? moment(currentTicket.resolved_time).format(
                              "DD/MM/YY hh:mm A"
                            )
                          : "N/A"}
                      </span>
                      <span>|</span>
                      <span>
                        Location:{" "}
                        {currentAgentData.location
                          ? getLocationName(currentAgentData?.location)
                          : "N/A"}
                      </span>
                    </div>
                  </div>
                </div>

                {currentAgentData.role === "customer_reps" && (
                  <>
                    {currentTicket && (
                      <div>
                        <Menu
                          as="div"
                          className="relative inline-block text-left"
                        >
                          <div className="relative">
                            <Menu.Button>
                              <BsThreeDotsVertical
                                size={30}
                                className="text-blue-700 hover:text-blue-800 cursor-pointer"
                                aria-hidden="true"
                              />
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="z-10 absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                              <div className="px-1 py-1 ">
                                <>
                                  <Menu.Item className="mb-1">
                                    <button
                                      type="button"
                                      onClick={() => {
                                        setEscalateTicketModal(true);
                                      }}
                                      className={`text-gray-900 group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-green-500 hover:text-white`}
                                    >
                                      Escalate
                                    </button>
                                  </Menu.Item>
                                </>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                    )}
                  </>
                )}
              </div>

              <hr className="w-full " />

              <div className="flex flex-col flex-auto flex-shrink-0 rounded-2xl bg-gray-100 h-[300px] p-4 mt-4">
                <div className="flex flex-col overflow-x-auto mb-4">
                  <div className="flex flex-col">
                    <div className="grid grid-cols-12 gap-y-2">
                      {allMessages?.map((item, i) => (
                        <SingleMsg
                          userData={userData}
                          item={item}
                          data={currentAgentData}
                          i={i}
                        />
                      ))}
                      <div ref={messagesEndRef} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : loading ? (
            <div className="w-full flex flex-col justify-center items-center">
              {/* <SyncLoader color="#22c553" /> */}
            </div>
          ) : (
            <div className="w-full flex flex-col justify-center items-center">
              <h1 className="text-xl">No Chats Available</h1>
              {/* <img src={Logo} alt="Example" className="h-24" /> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Agent;
