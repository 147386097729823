import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Form,
  Input,
  Select,
  Tabs,
  Table,
  Switch,
  Radio,
} from "antd";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import axios from "axios";
import { EditOutlined, DeleteOutlined, Delete } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ChatIcon from "@mui/icons-material/Chat";
import { toast } from "react-toastify";
import EditRepModal from "../components/modals/EditRepModal";
import { Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import { get_api, post_api } from "../helper/Api";
import ConfirmationModal from "../components/modals/ConfirmationModal";
const { Option } = Select;
const { TabPane } = Tabs;

const Reps = () => {
  const navigate = useNavigate();
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [locations, setLocations] = useState([]);
  const [parentReps, setParentReps] = useState([]);
  const [mainReps, setMainReps] = useState([]);
  const [activeTab, setActiveTab] = useState("mainReps");
  const [form] = Form.useForm();
  const [editRepData, setEditRepData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [uniqueLocations, setUniqueLocations] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [selected, setSelected] = useState(null);

  const createRep = async (values) => {
    console.log("func executed");
    try {
      console.log("inside executed");
      setLoading(true);

      console.log({ values });

      let payload = {
        ...values,
        mobile: values.mobile
          ? values.mobile.startsWith("+")
            ? values.mobile.substring(1)
            : values.mobile
          : "",
        location: values.location ? values.location.toString() : "",
      };

      const response = await post_api(`/reps/create-new-rep`, payload);

      console.log("resposne");
      // console.log({ response.error.data.message });
      // if (response.data.status) {
      toast.success(response?.data?.message);
      form.resetFields();
      getAllReps();
      getAllParentReps();
      setAddModalVisible(false);
      setLoading(false);
      // }
    } catch (err) {
      toast.error(err?.response?.data?.message);
      setLoading(false);
    }
  };

  const getAllLocation = async () => {
    try {
      const response = await get_api(`/get-all-active-restaurants`);

      setLocations(response.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getAllParentReps = async () => {
    try {
      const response = await get_api(`/reps/get-all-primary-reps`);

      setParentReps(response.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getAllReps = async () => {
    try {
      setLoading(true);
      const response = await get_api(`/reps/get-all-reps`);

      setMainReps(response.data.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getUniqueLocations = async () => {
    try {
      const response = await get_api(`/reps/get-unique-locations-id`);

      setUniqueLocations(response.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAllLocation();
    getAllParentReps();
    getAllReps();
    getUniqueLocations();
  }, []);

  // useEffect(() => {
  //   getAllReps();
  // },[]);

  const handleAddModalCancel = () => {
    form.resetFields();
    setAddModalVisible(false);
    setSelectedRole(null);
  };

  const handleEdit = (record) => {
    setEditRepData(record);

    form.setFieldsValue(record);

    setEditModalVisible(true);
  };

  const handleAddReps = () => {
    form.resetFields();
    setAddModalVisible(true);
  };

  const handleRoleChange = (e) => {
    console.log("hii", e.target.value);
    setSelectedRole(e.target.value);
  };

  const handleTabChange = (key) => {
    setSelectedRole(null);
    setActiveTab(key);
  };

  const switchRepsChange = async (checked, record) => {
    try {
      setLoading(true);
      const newStatus = !record.is_active;

      const response = await post_api(
        `/reps/toggle-rep/${record.id}/${newStatus}`,
        {}
      );

      toast.success(response.data.message);
      console.log("Hello");
      getAllReps();
    } catch (error) {
      console.error("Error toggling location:", error);
      toast.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (record) => {
    try {
      setLoading(true);
      const response = await post_api(`/reps/delete-rep/${record.id}`);
      if (response.data.status) {
        toast.success(response.data.message);
      }
      setLoading(false);
      getAllReps();
      console.log(response);
    } catch (e) {
      setLoading(false);
      // if (e.response.data.status) {
      toast.error(e.response.data.message);
      // }
      console.log(e);
    }
    console.log("Delete Customer", record.id);
  };

  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "sno",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "Active Tickets",
      dataIndex: "active_tickets",
      key: "active_tickets",
    },
    {
      title: "Resolved Tickets",
      dataIndex: "resolved_tickets",
      key: "resolved_tickets",
    },
    // {
    //   title: "Reps",
    //   dataIndex: "reps",
    //   key: "reps",
    //   ellipsis: true,
    //   render: (text) => (
    //     <Tooltip className="" placement="topLeft" title={text}>
    //       <span>{text}</span>
    //     </Tooltip>
    //   ),
    // },
    {
      title: "Location",
      dataIndex: "loc_name",
      key: "mobile",
      ellipsis: true,
      render: (text) => (
        <Tooltip placement="topLeft" title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "status",
      render: (checked, record) => (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          checked={checked === true}
          className="ml-2"
          onChange={(checked) => switchRepsChange(checked, record)}
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <span className="text-[#1890ff] cursor-pointer">
          <EditOutlined
            style={{ marginRight: 8 }}
            onClick={() => handleEdit(record)}
            titleAccess="Edit"
          />

          <ChatIcon
            style={{ marginRight: 8 }}
            onClick={() => navigate(`/agent/${record.id}`)}
            titleAccess="Chat"
          />
          <DeleteOutlined
            style={{ marginRight: 8, color: "#ff0505" }}
            onClick={() => {
              setSelected(record);
              setConfirmModal(true);
            }}
            titleAccess="Delete"
          />
        </span>
      ),
    },
  ];

  const column2 = [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "sno",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "Active Tickets",
      dataIndex: "active_tickets",
      key: "active_tickets",
    },
    {
      title: "Escalated Tickets",
      dataIndex: "escalated_tickets",
      key: "escalated_tickets",
    },
    {
      title: "Resolved Tickets",
      dataIndex: "resolved_tickets",
      key: "resolved_tickets",
    },
    {
      title: "Location",
      dataIndex: "loc_name",
      key: "mobile",
      ellipsis: true,
      render: (text) => (
        <Tooltip placement="topLeft" title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    // {
    //   title: "Assigned To",
    //   dataIndex: "parent",
    //   key: "assignedTo",
    // },

    {
      title: "Status",
      dataIndex: "is_active",
      key: "status",
      render: (checked, record) => (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          checked={checked === true}
          className="ml-2"
          onChange={(checked) => switchRepsChange(checked, record)}
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <span className="text-[#1890ff] cursor-pointer flex items-center">
          <EditOutlined
            style={{ marginRight: 8 }}
            onClick={() => handleEdit(record)}
            titleAccess="Edit"
          />

          <ChatIcon
            style={{ marginRight: 8 }}
            onClick={() => navigate(`/agent/${record.id}`)}
            titleAccess="Chat"
          />

          <DeleteOutlined
            style={{ marginRight: 8, color: "#ff0505" }}
            // onClick={() => handleDelete(record)}
            onClick={() => {
              setSelected(record);
              setConfirmModal(true);
            }}
            titleAccess="Delete"
          />
        </span>
      ),
    },
  ];

  console.log({ uniqueLocations });

  return (
    <div className="container mx-auto mt-8 p-4 bg-gray-100 shadow-md rounded-md">
      <ConfirmationModal
        isModalOpen={confirmModal}
        setIsModalOpen={setConfirmModal}
        handleSubmit={() => {
          handleDelete(selected);
        }}
        handleCancel1={() => {
          setSelected(null);
        }}
      />

      <div className="flex items-center justify-end">
        <Button type="primary" onClick={handleAddReps}>
          Add Reps
        </Button>
      </div>

      <EditRepModal
        editModalVisible={editModalVisible}
        setEditModalVisible={setEditModalVisible}
        editRepData={editRepData}
        setEditRepData={setEditRepData}
        getAllReps={getAllReps}
        parentReps={parentReps}
        locations={locations}
        uniqueLocations={uniqueLocations}
        getAllLocation={getAllLocation}
        getUniqueLocations={getUniqueLocations}
      />

      <Modal
        title="Add Representative"
        visible={addModalVisible}
        onCancel={handleAddModalCancel}
        maskClosable={false}
        footer={[
          <Button key="cancel" onClick={handleAddModalCancel}>
            Cancel
          </Button>,
          <Button
            form="addRepsForm"
            key="submit"
            htmlType="submit"
            type="primary"
            loading={loading}
            // onClick={createRep}
            //  onClick={() => form.submit()}
          >
            Submit
          </Button>,
        ]}
      >
        <Form
          form={form}
          id="addRepsForm"
          onFinish={createRep}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          style={{ marginTop: "20px" }}
        >
          <Form.Item
            label="Role"
            name="role"
            rules={[{ required: true, message: "Please select a role!" }]}
          >
            <Radio.Group onChange={handleRoleChange} value={selectedRole}>
              <Radio key="manager" value="manager">
                Manager
              </Radio>
              <Radio key="customer_reps" value="customer_reps">
                Customer Reps
              </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: "Please input your name!" }]}
          >
            <Input placeholder="Enter name" />
          </Form.Item>
          <Form.Item
            name="mobile"
            label="Number"
            rules={[
              {
                required: true,
                message: "Phone number must be more than 6 digits.",
                min: 7,
              },
            ]}
            className="mb-4"
          >
            <PhoneInput
              international
              defaultCountry="GB"
              placeholder="Enter phone number"
              value={form.getFieldValue("mobile") || ""}
              onChange={(value) => form.setFieldsValue({ mobile: value })}
              className="w-full p-2 rounded border border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
            />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please input a valid email!",
              },
            ]}
          >
            <Input placeholder="Enter email" />
          </Form.Item>

          {/* {selectedRole === "customer_reps" && (
            <>
              <Form.Item
                label="Parent Reps"
                name="parent_reps"
                rules={[
                  { required: true, message: "Please input parent reps!" },
                ]}
              >
                <Select placeholder="Select a Parent Reps">
                  {parentReps.map((parents) => (
                    <Option key={parents.id} value={parents.id}>
                      {parents.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
             
            </>
          )} */}

          <Form.Item
            label="Location"
            name="location"
            rules={[{ required: true, message: "Please select a location!" }]}
          >
            <Select
              placeholder="Select a location"
              mode="multiple"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              dropdownRender={(menu) => (
                <div style={{ maxHeight: "400px", overflow: "auto" }}>
                  {menu}
                </div>
              )}
            >
              {selectedRole === "customer_reps"
                ? locations
                    .filter((location) => uniqueLocations.includes(location.id))
                    .map((location) => (
                      <Option key={location.id} value={location.id}>
                        {`${location.name} - ${location.location}`}
                      </Option>
                    ))
                : locations.map((location) => (
                    <Option key={location.id} value={location.id}>
                      {`${location.name} - ${location.location}`}
                    </Option>
                  ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>

      <Tabs
        defaultActiveKey="customerReps"
        size="small"
        onChange={handleTabChange}
      >
        <TabPane tab="Customer Reps" key="customerReps">
          <Table
            dataSource={mainReps
              .filter((rep) => rep.role === "customer_reps" && !rep.is_deleted)
              .map((item, i) => ({
                ...item,
                sno: i + 1,
                // parent: parentReps
                //   ?.filter((obj) => obj.id == item.parent_reps)
                //   .map((item) => {
                //     return item.name;
                //   }),
                // loc_name: item.location,
                loc_name: locations
                  .filter((obj) => {
                    let currentItem = item.location
                      .split(",")
                      .map((item) => parseInt(item));
                    console.log({ currentItem });
                    return currentItem.includes(obj.id);
                  })
                  .map((loc) => {
                    return `${loc.location}`;
                  })
                  ?.toString(),
              }))}
            columns={column2}
            loading={loading}
          />
        </TabPane>
        <TabPane tab="Manager" key="mainReps">
          <Table
            dataSource={mainReps
              .filter((rep) => rep.role === "manager" && !rep.is_deleted)
              .map((item, i) => ({
                ...item,
                sno: i + 1,
                reps: mainReps
                  .filter((rep) => item.id === parseInt(rep.parent_reps))
                  .map((current_rep) => `${current_rep.name}`)
                  ?.toString(),
                loc_name: locations
                  .filter((obj) => {
                    let currentItem = item.location
                      .split(",")
                      .map((item) => parseInt(item));
                    console.log({ currentItem });
                    return currentItem.includes(obj.id);
                  })
                  .map((loc) => {
                    return `${loc.location}`;
                  })
                  ?.toString(),
              }))}
            columns={columns}
            loading={loading}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Reps;
