import moment from "moment";
import React from "react";

const SingleChatMessage = ({ userData, item, i }) => {
  const is_customer =
    item.sender_id === userData.mobile || item.sender_id.length > 5 ? 1 : 0;

  const messageTime = moment(item.createdAt);

  let dateTime = messageTime.format("hh:mm A");

  return (
    <div
      className={`${
        parseInt(item.sender_id) !== userData.mobile &&
        item.sender_id.length > 5
          ? "col-start-1 col-end-12"
          : "col-start-2 col-end-13"
      } p-3 rounded-lg`}
      key={i}
    >
      <div
        className={`flex ${
          parseInt(item.sender_id) !== userData.mobile &&
          item.sender_id.length > 5
            ? "flex-row"
            : "flex-row-reverse"
        } items-center`}
      >
        <div
          className={`overflow-hidden flex items-center justify-center h-10 w-10 rounded-full flex-shrink-0 ${
            parseInt(item.sender_id) !== userData.mobile &&
            item.sender_id.length > 5
              ? "bg-white"
              : "bg-indigo-500 text-white"
          }`}
        >
          {is_customer ? (
            userData.name ? (
              userData.name
                .split(" ")
                .map((part) => part.charAt(0))
                .join("")
            ) : (
              "UC"
            )
          ) : (
            <img
              src={"/gk-logo.jpg"}
              alt={"logo_img"}
              style={{ objectFit: "cover" }}
            />
          )}
        </div>
        <div
          style={{ minWidth: "100px", maxWidth: "80%" }}
          className={` ${is_customer ? "" : "mr-4"}`}
        >
          <div
            className={`relative ml-3 text-sm ${
              parseInt(item.sender_id) !== userData.mobile &&
              item.sender_id.length > 5
                ? "bg-white"
                : "bg-indigo-500 text-white"
            } py-2 shadow rounded-xl`}
          >
            <div className="flex flex-col  items-center px-2 py-1">
              <span
                className={`flex-grow ${
                  is_customer ? "text-left" : "text-right"
                } w-full`}
              >
                {item.message}
              </span>
              <span
                className={`w-full ${
                  is_customer ? "text-left" : "text-right"
                } `}
              >
                <span
                  className={`${
                    is_customer ? "text-gray-400" : "text-white"
                  } text-xs`}
                >
                  {dateTime}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleChatMessage;
