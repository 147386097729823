import axios from "axios";

// export const BASE_API = "http://localhost:3000/";
export const BASE_API =
  process.env.REACT_APP_SERVER_URL + "/" || "https://grwoth.techcarrel.in/";

// const serverURL = "http://173.212.241.37:4006/api";
// export const socketServerUrl = "http://173.212.241.37:4006";

// const serverURL = "https://grwoth.techcarrel.in/api";
export const socketServerUrl =
  process.env.REACT_APP_SOCKET_SERVER_URL || "https://grwoth.techcarrel.in";

const serverURL =
  process.env.REACT_APP_SERVER_URL + "/api" || "http://localhost:4006/api";
// export const socketServerUrl = "http://localhost:4006";

const api = axios.create({
  baseURL: serverURL,
  // withCredentials: true,
});

// http://localhost:3001/api/admin-login
// "https://www.obituary.api.techcarrel.in/";
// "http://localhost:3002/"
// export async function CallPOSTFORMAPI(url, params = [], headers = {}) {
//   try {
//     let token = localStorage.getItem("callflow_token");
//     let AllHeaders = { ...headers };
//     if (token) {
//       AllHeaders = { Authorization: token, ...headers };
//     }

//     let res = await fetch(BASE_API + url, {
//       method: "POST",
//       headers: { "Content-Type": "multipart/form-data", ...AllHeaders },
//       body: params,
//     });

//     if (!res.ok) {
//       return { status: false, msg: "No Data Found", data: [] };
//     }
//     let resultData = await res.json();
//     return { status: true, msg: "", data: resultData };
//   } catch (e) {
//     return { status: false, msg: e.message, data: [] };
//   }
// }

// =====================
export const CallGetApi = async (url, params = [], headers = {}) => {
  try {
    let token = localStorage.getItem("obituary_token");
    let AllHeaders = { ...headers };
    if (token) {
      AllHeaders = { Authorization: token, ...headers };
    }

    const response = await fetch.get(BASE_API + url, {
      method: "GET",
      headers: { ...AllHeaders },
    });
    return response;
  } catch (error) {
    return { status: false, msg: error.message, data: [] };
  }
};

export async function CallPOSTAPI(url, params = [], headers = {}) {
  try {
    let token = localStorage.getItem("obituary_token");
    let AllHeaders = { ...headers };
    if (token) {
      AllHeaders = { Authorization: token, ...headers };
    }

    let res = await fetch(BASE_API + url, {
      method: "POST",
      headers: { "Content-Type": "application/json", ...AllHeaders },
      body: JSON.stringify(params),
    });

    if (!res.ok) {
      return { status: false, msg: "No Data Found", data: [] };
    }
    let resultData = await res.json();
    return { status: true, msg: "", data: resultData };
  } catch (e) {
    return { status: false, msg: e.message, data: [] };
  }
}

export const post_api = async (url, data) => {
  return await api.post(url, data);
};

export const get_api = async (url) => {
  return await api.get(url);
};
