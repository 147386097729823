import React, { useState } from "react";
import { Upload, Button, Spin } from "antd";
import { UploadOutlined, DownloadOutlined } from "@ant-design/icons";
import axios from "axios";
import { BASE_API } from "../../helper/Api";

const WeeklyOperations = () => {
  const [loadingStates, setLoadingStates] = useState({
    JustEats_reviews: false,
    justEat_refund: false,
    ubereats_inaccurate_orders: false,
    ubereats_feedback: false,
    Deliveroo_performance: false,
    Deliveroo_review: false,
    Deliveroo_refund: false,
  });

  const [orderUploadMessage, setOrderUploadMessage] = useState({
    JustEats_reviews: "",
    justEat_refund: "",
    ubereats_inaccurate_orders: "",
    ubereats_feedback: "",
    Deliveroo_performance: "",
    Deliveroo_review: "",
    Deliveroo_refund: "",
  });

  const [orderInsertedCount, setOrderInsertedCount] = useState({
    JustEats_reviews: 0,
    justEat_refund: 0,
    ubereats_inaccurate_orders: 0,
    ubereats_feedback: 0,
    Deliveroo_performance: 0,
    Deliveroo_review: 0,
    Deliveroo_refund: 0,
  });

  const [orderNotInsertedCount, setOrderNotInsertedCount] = useState({
    JustEats_reviews: 0,
    justEat_refund: 0,
    ubereats_inaccurate_orders: 0,
    ubereats_feedback: 0,
    Deliveroo_performance: 0,
    Deliveroo_review: 0,
    Deliveroo_refund: 0,
  });

  const [orderNotInsertedData, setOrderNotInsertedData] = useState({
    JustEats_reviews: [],
    justEat_refund: [],
    ubereats_inaccurate_orders: [],
    ubereats_feedback: [],
    Deliveroo_performance: [],
    Deliveroo_review: [],
    Deliveroo_refund: [],
  });

  const beforeUpload = (file, uploadType) => {
    const isExcel =
      file.type === "application/vnd.ms-excel" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    if (!isExcel) {
      setOrderUploadMessage((prevMessages) => ({
        ...prevMessages,
        [uploadType]: "You can only upload Excel files!",
      }));

      setTimeout(() => {
        setOrderUploadMessage((prevMessages) => ({
          ...prevMessages,
          [uploadType]: "",
        }));
      }, 5000);
    }
    return isExcel;
  };

  const uploadTypeLookup = {
    JustEats_reviews: `${BASE_API}api/weekly-operations/justeats-reviews`,
    justEat_refund: `${BASE_API}api/weekly-operations/justeats-refunds`,
    ubereats_inaccurate_orders: `${BASE_API}api/weekly-operations/uber-eats-inaccurate-orders`,
    ubereats_feedback: `${BASE_API}api/weekly-operations/uber-eats-feedback`,
    Deliveroo_performance: `${BASE_API}api/weekly-operations/deliveroo-performance`,
    Deliveroo_review: `${BASE_API}api/weekly-operations/create-deliveroo-review`,
    Deliveroo_refund: `${BASE_API}api/weekly-operations/create-deliveroo-refund`,
  };

  const handleUpload = async (info, uploadType) => {
    const { file } = info;

    setLoadingStates((prevState) => ({
      ...prevState,
      [uploadType]: true,
    }));

    const formData = new FormData();
    formData.append("file", file);
    formData.append("type", uploadType);

    try {
      const route = uploadTypeLookup[uploadType];
      if (!route) {
        console.warn(`Unsupported upload type: ${uploadType}`);
        return;
      }

      const response = await axios.post(route, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (response.status === 200) {
        setOrderUploadMessage((prevMessages) => ({
          ...prevMessages,
          [uploadType]: response.data.message || "Upload successful",
        }));
        setOrderInsertedCount((prevCounts) => ({
          ...prevCounts,
          [uploadType]: response.data.Inserted || 0,
        }));
        setOrderNotInsertedCount((prevCounts) => ({
          ...prevCounts,
          [uploadType]: response.data.NotInserted || 0,
        }));
        setOrderNotInsertedData((prevData) => ({
          ...prevData,
          [uploadType]: response.data.notInsertedData || [],
        }));
      }
    } catch (error) {
      setOrderUploadMessage((prevMessages) => ({
        ...prevMessages,
        [uploadType]: `All columns should be present in the excel file.`,
      }));
    } finally {
      setLoadingStates((prevState) => ({
        ...prevState,
        [uploadType]: false,
      }));
      clearUploadMessage();
    }
  };

  const databaseCategories = [
    {
      name: "JustEat Review",
      type: "JustEats_reviews",
      link: "/justeat_reviews.xlsx",
    },
    {
      name: "JustEat Refund",
      type: "justEat_refund",
      link: "/justeats_refund.xlsx",
    },
    {
      name: "Uber Inaccurate Order",
      type: "ubereats_inaccurate_orders",
      link: "/ubereats_inaccurate_orders.xlsx",
    },
    {
      name: "Uber Feedback",
      type: "ubereats_feedback",
      link: "/ubereats_feedback.xlsx",
    },
    {
      name: "Deliveroo Performance",
      type: "Deliveroo_performance",
      link: "/deliveroo_performance.xlsx",
    },
    {
      name: "Deliveroo Review",
      type: "Deliveroo_review",
      link: "/deliveroo_reviews.xlsx",
    },
    {
      name: "Deliveroo Refund",
      type: "Deliveroo_refund",
      link: "/deliveroo_refunds.xlsx",
    },
  ];

  const handleDrag = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const clearUploadMessage = () => {
    setTimeout(() => {
      setOrderUploadMessage({
        JustEats_reviews: "",
        justEat_refund: "",
        ubereats_inaccurate_orders: "",
        ubereats_feedback: "",
        Deliveroo_performance: "",
        Deliveroo_review: "",
        Deliveroo_refund: "",
      });
      setOrderInsertedCount({
        JustEats_reviews: 0,
        justEat_refund: 0,
        ubereats_inaccurate_orders: 0,
        ubereats_feedback: 0,
        Deliveroo_performance: 0,
        Deliveroo_review: 0,
        Deliveroo_refund: 0,
      });
      setOrderNotInsertedCount({
        JustEats_reviews: 0,
        justEat_refund: 0,
        ubereats_inaccurate_orders: 0,
        ubereats_feedback: 0,
        Deliveroo_performance: 0,
        Deliveroo_review: 0,
        Deliveroo_refund: 0,
      });
      setOrderNotInsertedData({
        JustEats_reviews: [],
        justEat_refund: [],
        ubereats_inaccurate_orders: [],
        ubereats_feedback: [],
        Deliveroo_performance: [],
        Deliveroo_review: [],
        Deliveroo_refund: [],
      });
    }, 5000);
  };

  return (
    <div className="container" onDrop={handleDrag} onDragOver={handleDrag}>
      <div style={{ color: "black", textAlign: "left", marginBottom: "10px" }}>
        Note: Please download the sample file, paste your data, and then upload
        it.
      </div>
      <div className="grid grid-cols-2 gap-x-8 ">
        {databaseCategories.map((category) => (
          <div key={category.name} className="upload-section">
            <h3 className="font-semibold text-lg text-left text-[#0075FC]">
              {category.name}
            </h3>
            <div className="flex items-center gap-32 mt-2 flex-wrap pb-[1rem]">
              <Upload
                customRequest={(info) => handleUpload(info, category.type)}
                showUploadList={false}
                beforeUpload={(file) => beforeUpload(file, category.type)}
              >
                <Button
                  icon={<UploadOutlined />}
                  loading={loadingStates[category.type]}
                  disabled={loadingStates[category.type]}
                >
                  {loadingStates[category.type] ? <Spin /> : "Upload Excel"}
                </Button>
              </Upload>

              <Button style={{ border: "1px solid #1890ff", color: "#1890ff" }}>
                <a
                  href={category.link}
                  download
                  style={{ color: "#1890ff", textDecoration: "none" }}
                >
                  <DownloadOutlined style={{ marginRight: "8px" }} />
                  Download Sample
                </a>
              </Button>
            </div>
            <div>
              {orderUploadMessage[category.type] && (
                <div style={{ marginTop: "1px" }}>
                  <p
                    style={{
                      color:
                        orderUploadMessage[category.type] ===
                          "You can only upload Excel files!" ||
                        orderUploadMessage[category.type] ===
                          "All columns should be present in the excel file."
                          ? "red"
                          : "black",
                    }}
                  >
                    {orderUploadMessage[category.type]}
                  </p>
                  {orderInsertedCount[category.type] >= 1 && (
                    <p style={{ color: "green" }}>
                      Inserted: {orderInsertedCount[category.type]} records
                    </p>
                  )}
                  {orderNotInsertedCount[category.type] >= 1 && (
                    <p style={{ color: "red" }}>
                      Not Inserted: {orderNotInsertedCount[category.type]}{" "}
                      records
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WeeklyOperations;
