import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Input, Button, Table, Space, Modal, Form, Tabs } from "antd";
import { EditOutlined } from "@mui/icons-material";
import axios from "axios";
import { Switch } from "antd";
import ObituaryDeatils from "../../pages/obituary/ObituaryDetails";
// import ObituaryDeatilsUser from "../User/ObituaryDeatilsUser";
import Enquiry from "../Enquiry";
import User from "../User";
import DailyOperation from "../SqlUpload/DailyOperations";
import WeeklyOperation from "../SqlUpload/WeeklyOperation";

const { TabPane } = Tabs;

export default function DataUploads() {
  const [restaurantData, setRestaurantData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editId, setEditId] = useState(null);
  const [form] = Form.useForm();

  return (
    <>
      <div className="container mx-auto mt-8 p-4 bg-gray-100 shadow-md rounded-md ">
        <div className="flex items-end justify-end">
          {/* <Button type="primary" onClick={() => setAddModalVisible(true)}>
            Add Restaurant
          </Button> */}
        </div>

        <Tabs defaultActiveKey="deliveroo" size="small">
          <TabPane tab="Deliveroo" key="deliveroo">
            <ObituaryDeatils />
          </TabPane>

          <TabPane tab="Uber" key="uber">
            <Enquiry />
          </TabPane>
          <TabPane tab="Justeat" key="justeat">
            <User />
          </TabPane>
          <TabPane tab="Daily Operation" key="Daily Operationsql">
            <DailyOperation />
          </TabPane>
          <TabPane tab="Weekly Operation" key="Weekly_Operationsql">
            <WeeklyOperation />
          </TabPane>
        </Tabs>
      </div>
    </>
  );
}
