import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import { toast } from "react-toastify";
import { SyncLoader } from "react-spinners";
import { post_api } from "../helper/Api";

const EscalateTicketModal = ({
  isOpen,
  setIsOpen,
  currentTicket,
  allManagers,
  refetchData,
}) => {
  const [manager, setManager] = useState(null);
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleMessage = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);

      const admin_id = JSON.parse(localStorage.getItem("gk-admin"));

      if (!admin_id.user_id) {
        return;
      }

      const payload = {
        manager_id: manager,
        admin_id: admin_id?.user_id,
      };
      console.log({ payload });
      const response = await post_api(
        `/admin/escalate-ticket/${currentTicket?.id}`,
        payload
      );
      if (response.data.status) {
        closeModal();
        toast.success(response.data.message);
        refetchData();
      }
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      toast.error(error.response.data.message);
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Escalate Ticket
                </Dialog.Title>
                {loading ? (
                  <div className="w-full flex justify-center items-center mt-5">
                    <SyncLoader color="#22c553" />
                  </div>
                ) : (
                  <div className="mt-5">
                    <div className="flex items-center justify-center">
                      {/* <!-- Author: FormBold Team --> */}
                      <div className="mx-auto w-full max-w-[550px] bg-white">
                        <form onSubmit={handleMessage}>
                          <div className="-mx-3 flex flex-wrap">
                            <div className="w-full px-3">
                              <div className="mb-5">
                                <label
                                  htmlFor="date"
                                  className="mb-3 block text-base font-medium text-[#07074D]"
                                >
                                  Manager
                                </label>
                                <select
                                  className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                                  value={manager}
                                  onChange={(e) => setManager(e.target.value)}
                                  aria-label="Select Picker"
                                >
                                  <option value={null}>Select Manager</option>
                                  {allManagers?.map((option, index) => (
                                    <option key={index} value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>

                          <div>
                            <button type="submit" className="submit-btn">
                              Submit
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default EscalateTicketModal;
