import React, { useState, useEffect } from "react";
import { Spin, Upload, message, Button, Modal, Table } from "antd";
import { UploadOutlined, DownloadOutlined } from "@ant-design/icons";
import axios from "axios";
import { BASE_API } from "../../helper/Api";
import { toast } from "react-toastify";
import { EyeOutlined } from "@ant-design/icons";

export default function ObituaryDetails() {
  const [orderLoading, setOrderLoading] = useState(false);
  const [speedLoading, setSpeedLoading] = useState(false);
  const [downtimeLoading, setDowntimeLoading] = useState(false);
  const [uploadMessage, setUploadMessage] = useState(""); // New state for upload message
  const [insertedCount, setInsertedCount] = useState(0); // Add this line
  const [notInsertedCount, setNotInsertedCount] = useState(0); // Add this line
  const [modalVisible, setModalVisible] = useState(false);
  const [notInsertedData, setNotInsertedData] = useState([]);
  const [orderuploadMessage, setOrderUploadMessage] = useState(""); // New state for upload message
  const [orderinsertedCount, setOrderInsertedCount] = useState(0); // Add this line
  const [ordernotInsertedCount, setOrderNotInsertedCount] = useState(0); // Add this line
  const [ordernotInsertedData, setOrderNotInsertedData] = useState([]);
  const [openrateuploadMessage, setOpenrateUploadMessage] = useState(""); // New state for upload message
  const [openratensertedCount, setOpenrateInsertedCount] = useState(0); // Add this line
  const [openratenotInsertedCount, setOpenrateNotInsertedCount] = useState(0); // Add this line
  const [openratenotInsertedData, setOpenrateNotInsertedData] = useState([]);

  const handleFileChange = (info, fileType) => {
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    } else if (info.file.status === "removed") {
      // Handle file removal if needed
    }
  };

  const handleorder = async ({ file, onSuccess, onError }, fileType) => {
    const isExcelFile = (file) => {
      const acceptedFormats = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "text/csv",
      ];
      return acceptedFormats.includes(file.type);
    };

    const formData = new FormData();
    formData.append("file", file);

    try {
      if (!isExcelFile(file)) {
        toast.error("Please upload a valid Excel file (CSV or XLSX format).");
        return;
      }

      setOrderLoading(true);

      const response = await axios.post(
        BASE_API + "api/upload-excels",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Ensure proper content type for FormData
          },
        }
      );

      setOrderUploadMessage(response.data.message);
      setOrderInsertedCount(response.data.Inserted || 0);
      setOrderNotInsertedCount(response.data.NotInserted || 0);
      setOrderNotInsertedData(response.data.notInsertedData || 0);
      // toast.success(`${fileType} file uploaded successfully.`);
    } catch (error) {
      toast.error(`${fileType} file upload failed. Please try again.`);
    } finally {
      setOrderLoading(false);
    }
  };

  const handlespeed = async ({ file, onSuccess, onError }, fileType) => {
    const isExcelFile = (file) => {
      const acceptedFormats = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "text/csv",
      ];
      return acceptedFormats.includes(file.type);
    };
    const formData = new FormData();
    formData.append("file", file);

    try {
      if (!isExcelFile(file)) {
        toast.error("Please upload a valid Excel file (CSV or XLSX format).");
        return;
      }
      setSpeedLoading(true);
      const response = await axios.post(
        BASE_API + "api/upload-speed-order",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Ensure proper content type for FormData
          },
        }
      );
      // toast.success(response.data.message);
      setUploadMessage(response.data.message);
      setInsertedCount(response.data.Inserted || 0);
      setNotInsertedCount(response.data.NotInserted || 0);
      setNotInsertedData(response.data.notInsertedData || 0);
    } catch (error) {
      toast.error(`${fileType} file upload failed. Please try again.`);
    } finally {
      setSpeedLoading(false);
    }
  };

  const handleopenrate = async ({ file, onSuccess, onError }, fileType) => {
    const isExcelFile = (file) => {
      const acceptedFormats = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "text/csv",
      ];
      return acceptedFormats.includes(file.type);
    };
    const formData = new FormData();
    formData.append("file", file);

    try {
      if (!isExcelFile(file)) {
        toast.error("Please upload a valid Excel file (CSV or XLSX format).");
        return;
      }
      setDowntimeLoading(true);
      const response = await axios.post(
        BASE_API + "api/upload-openrate",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // toast.success(response.data.message);
      setOpenrateUploadMessage(response.data.message);
      setOpenrateInsertedCount(response.data.Inserted || 0);
      setOpenrateNotInsertedCount(response.data.NotInserted || 0);
      setOpenrateNotInsertedData(response.data.notInsertedData || 0);
    } catch (error) {
      toast.error(`${fileType} file upload failed. Please try again.`);
    } finally {
      setDowntimeLoading(false);
    }
  };

  const beforeUpload = (file) => {
    return true;
  };

  const handleViewDetails = () => {
    setModalVisible(true);
  };

  const handleTimer = (callback) => {
    const timeoutId = setTimeout(() => {
      callback(); // Execute the provided callback function
    }, 10000);

    return () => clearTimeout(timeoutId);
  };

  useEffect(() => {
    if (orderuploadMessage) {
      handleTimer(() => {
        // Reset the state for the "Order" section
        setOrderUploadMessage("");
        setOrderInsertedCount(0);
        setOrderNotInsertedCount(0);
        setOrderNotInsertedData([]);
      });
    }
  }, [orderuploadMessage]);

  useEffect(() => {
    if (openrateuploadMessage) {
      handleTimer(() => {
        // Reset the state for the "Downtime" section
        setOpenrateUploadMessage("");
        setOpenrateInsertedCount(0);
        setOpenrateNotInsertedCount(0);
        setOpenrateNotInsertedData([]);
      });
    }
  }, [openrateuploadMessage]);
  useEffect(() => {
    if (uploadMessage) {
      handleTimer(() => {
        // Reset the state for the "Order" section
        setUploadMessage("");
        setInsertedCount(0);
        setNotInsertedCount(0);
        setNotInsertedData([]);
      });
    }
  }, [uploadMessage]);

  return (
    <>
      <div className="">
        <div
          style={{ color: "black", textAlign: "left", marginBottom: "10px" }}
        >
          Note: Please download the sample file, paste your data, and then
          upload it.
        </div>
        <div className="flex justify-between px-4 lg:px-4 py-4">
          <div className="font-semibold text-lg text-left items-center text-[#0075FC] flex justify-between mt-1 w-[100%]">
            Order
          </div>
        </div>

        <div className="flex justify-between pb-6 mb-3">
          <div className="w-[100%] lg:px-4 px-4">
            <div className="grid grid-cols-2 mt-1 w-[100%] gap-5 min-h-[100px] relative ">
              {orderLoading ? (
                <div className="flex justify-center items-center absolute top-0 ">
                  <Spin size="large">
                    <h1 className="mt-20 text-blue-900">Loading...</h1>
                  </Spin>
                </div>
              ) : (
                <>
                  <Upload
                    customRequest={(info) => handleorder(info, "order")}
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    onChange={(info) => handleFileChange(info, "order")}
                  >
                    <Button
                      icon={<UploadOutlined />}
                      loading={orderLoading}
                      disabled={orderLoading}
                    >
                      Upload Excel
                    </Button>
                  </Upload>

                  <Button
                    style={{
                      border: "1px solid #1890ff",
                      color: "#1890ff",
                      width: "229px",
                    }}
                  >
                    <a
                      href="/newsample.xlsx"
                      download
                      style={{ color: "#1890ff", textDecoration: "none" }}
                    >
                      <DownloadOutlined style={{ marginRight: "8px" }} />
                      Download Sample
                    </a>
                  </Button>
                  <div style={{ marginTop: "10px" }}>
                    {/* Display the upload message */}
                    {orderuploadMessage && (
                      <div>
                        <p style={{ marginBottom: "5px" }}>
                          {orderuploadMessage}
                        </p>
                        {orderinsertedCount > 0 && (
                          <p style={{ color: "green", marginBottom: "5px" }}>
                            Inserted: {orderinsertedCount} records
                          </p>
                        )}
                        {ordernotInsertedCount !== undefined && (
                          <div style={{ display: "flex", marginBottom: "5px" }}>
                            <p style={{ color: "red", marginRight: "10px" }}>
                              Not Inserted: {ordernotInsertedCount} records
                            </p>
                            {ordernotInsertedCount > 0 && (
                              <Button
                                type="link"
                                style={{
                                  marginTop: "-6px",
                                }}
                                icon={<EyeOutlined />} // Use the EyeOutlined icon
                                onClick={handleViewDetails}
                              >
                                View Details
                              </Button>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  <Modal
                    title="Not Inserted Details"
                    visible={modalVisible}
                    onCancel={() => setModalVisible(false)}
                    footer={null}
                  >
                    {ordernotInsertedData.length > 0 ? (
                      <Table
                        dataSource={ordernotInsertedData}
                        columns={[
                          {
                            title: "Column 1",
                            dataIndex: "column1",
                            key: "column1",
                          },
                          {
                            title: "Column 2",
                            dataIndex: "column2",
                            key: "column2",
                          },
                        ]}
                        pagination={false}
                      />
                    ) : (
                      <p>No details available for not inserted records.</p>
                    )}
                  </Modal>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="flex justify-between px-4 lg:px-4 py-4">
          <div className="font-semibold text-lg text-left items-center text-[#0075FC] flex justify-between mt-1 w-[100%]">
            Speed
          </div>
        </div>
        <div className="flex justify-between pb-6 mb-3">
          <div className="w-[100%] lg:px-4 px-4">
            <div className="grid grid-cols-2 mt-1 w-[100%] gap-5 min-h-[100px] relative ">
              {speedLoading ? (
                <div className="flex justify-center items-center absolute top-0 ">
                  <Spin size="large">
                    <h1 className="mt-20 text-blue-900">Loading...</h1>
                  </Spin>
                </div>
              ) : (
                <>
                  <Upload
                    customRequest={(info) => handlespeed(info, "openrate")}
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    onChange={(info) => handleFileChange(info, "openrate")}
                  >
                    <Button
                      icon={<UploadOutlined />}
                      loading={speedLoading}
                      disabled={speedLoading}
                    >
                      Upload Excel
                    </Button>
                  </Upload>

                  <Button
                    style={{
                      border: "1px solid #1890ff",
                      color: "#1890ff",
                      width: "229px",
                    }}
                  >
                    <a
                      href="/speedsample.xlsx"
                      download
                      style={{ color: "#1890ff", textDecoration: "none" }}
                    >
                      <DownloadOutlined style={{ marginRight: "8px" }} />
                      Download Sample
                    </a>
                  </Button>
                  <div style={{ marginTop: "10px" }}>
                    {/* Display the upload message */}
                    {uploadMessage && (
                      <div>
                        <p style={{ marginBottom: "5px" }}>{uploadMessage}</p>
                        {insertedCount > 0 && (
                          <p style={{ color: "green", marginBottom: "5px" }}>
                            Inserted: {insertedCount} records
                          </p>
                        )}
                        {notInsertedCount !== undefined && (
                          <div style={{ display: "flex", marginBottom: "5px" }}>
                            <p style={{ color: "red", marginRight: "10px" }}>
                              Not Inserted: {notInsertedCount} records
                            </p>
                            {notInsertedCount > 0 && (
                              <Button
                                type="link"
                                style={{
                                  marginTop: "-6px",
                                }}
                                icon={<EyeOutlined />} // Use the EyeOutlined icon
                                onClick={handleViewDetails}
                              >
                                View Details
                              </Button>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  <Modal
                    title="Not Inserted Details"
                    visible={modalVisible}
                    onCancel={() => setModalVisible(false)}
                    footer={null}
                  >
                    {notInsertedData.length > 0 ? (
                      <Table
                        dataSource={notInsertedData}
                        columns={[
                          {
                            title: "Column 1",
                            dataIndex: "column1",
                            key: "column1",
                          },
                          {
                            title: "Column 2",
                            dataIndex: "column2",
                            key: "column2",
                          },
                        ]}
                        pagination={false}
                      />
                    ) : (
                      <p>No details available for not inserted records.</p>
                    )}
                  </Modal>
                </>
              )}{" "}
            </div>
          </div>
        </div>
        <div className="flex justify-between px-4 lg:px-4 py-4">
          <div className="font-semibold text-lg text-left items-center text-[#0075FC] flex justify-between mt-1 w-[100%]">
            Openrate
          </div>
        </div>
        <div className="flex justify-between pb-6 mb-3">
          <div className="w-[100%] lg:px-4 px-4">
            <div className="grid grid-cols-2 mt-1 w-[100%] gap-5 min-h-[100px] relative ">
              {downtimeLoading ? (
                <div className="flex justify-center items-center absolute top-0 ">
                  <Spin size="large">
                    <h1 className="mt-20 text-blue-900">Loading...</h1>
                  </Spin>
                </div>
              ) : (
                <>
                  <Upload
                    customRequest={(info) => handleopenrate(info, "openrate")}
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    onChange={(info) => handleFileChange(info, "openrate")}
                  >
                    <Button
                      icon={<UploadOutlined />}
                      loading={downtimeLoading}
                      disabled={downtimeLoading}
                    >
                      Upload Excel
                    </Button>
                  </Upload>
                  <Button
                    style={{
                      border: "1px solid #1890ff",
                      color: "#1890ff",
                      width: "229px",
                    }}
                  >
                    <a
                      href="/openratesample.xlsx"
                      download
                      style={{ color: "#1890ff", textDecoration: "none" }}
                    >
                      <DownloadOutlined style={{ marginRight: "8px" }} />
                      Download Sample
                    </a>
                  </Button>
                  <div style={{ marginTop: "10px" }}>
                    {/* Display the upload message */}
                    {openrateuploadMessage && (
                      <div>
                        <p style={{ marginBottom: "5px" }}>
                          {openrateuploadMessage}
                        </p>
                        {openratensertedCount > 0 && (
                          <p style={{ color: "green", marginBottom: "5px" }}>
                            Inserted: {openratensertedCount} records
                          </p>
                        )}
                        {openratenotInsertedCount !== undefined && (
                          <div style={{ display: "flex", marginBottom: "5px" }}>
                            <p style={{ color: "red", marginRight: "10px" }}>
                              Not Inserted: {openratenotInsertedCount} records
                            </p>
                            {openratenotInsertedCount > 0 && (
                              <Button
                                type="link"
                                style={{
                                  marginTop: "-6px",
                                }}
                                icon={<EyeOutlined />} // Use the EyeOutlined icon
                                onClick={handleViewDetails}
                              >
                                View Details
                              </Button>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  <Modal
                    title="Not Inserted Details"
                    visible={modalVisible}
                    onCancel={() => setModalVisible(false)}
                    footer={null}
                  >
                    {openratenotInsertedData.length > 0 ? (
                      <Table
                        dataSource={openratenotInsertedData}
                        columns={[
                          {
                            title: "Column 1",
                            dataIndex: "column1",
                            key: "column1",
                          },
                          {
                            title: "Column 2",
                            dataIndex: "column2",
                            key: "column2",
                          },
                        ]}
                        pagination={false}
                      />
                    ) : (
                      <p>No details available for not inserted records.</p>
                    )}
                  </Modal>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
