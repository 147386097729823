import React, { useState } from "react";
import { Upload, Button, Spin } from "antd";
import { UploadOutlined, DownloadOutlined } from "@ant-design/icons";
import axios from "axios";
import { BASE_API } from "../../helper/Api";

const DailyOperations = () => {
  const [loadingStates, setLoadingStates] = useState({
    just_eat: false,
    uber_daily_order: false,
    uber_daily_downtime: false,
    deliveroo_daily_order: false,
    deliveroo_daily_speed: false,
    deliveroo_daily_open_rate: false,
  });

  const [orderUploadMessage, setOrderUploadMessage] = useState({
    just_eat: "",
    uber_daily_order: "",
    uber_daily_downtime: "",
    deliveroo_daily_order: "",
    deliveroo_daily_speed: "",
    deliveroo_daily_open_rate: "",
  });

  const [orderInsertedCount, setOrderInsertedCount] = useState({
    just_eat: 0,
    uber_daily_order: 0,
    uber_daily_downtime: 0,
    deliveroo_daily_order: 0,
    deliveroo_daily_speed: 0,
    deliveroo_daily_open_rate: 0,
  });

  const [orderNotInsertedCount, setOrderNotInsertedCount] = useState({
    just_eat: 0,
    uber_daily_order: 0,
    uber_daily_downtime: 0,
    deliveroo_daily_order: 0,
    deliveroo_daily_speed: 0,
    deliveroo_daily_open_rate: 0,
  });

  const [orderNotInsertedData, setOrderNotInsertedData] = useState({
    just_eat: [],
    uber_daily_order: [],
    uber_daily_downtime: [],
    deliveroo_daily_order: [],
    deliveroo_daily_speed: [],
    deliveroo_daily_open_rate: [],
  });

  const beforeUpload = (file, uploadType) => {
    const isExcel =
      file.type === "application/vnd.ms-excel" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    if (!isExcel) {
      setOrderUploadMessage((prevMessages) => ({
        ...prevMessages,
        [uploadType]: "You can only upload Excel files!",
      }));

      setTimeout(() => {
        setOrderUploadMessage((prevMessages) => ({
          ...prevMessages,
          [uploadType]: "",
        }));
      }, 5000);
    }

    return isExcel;
  };

  const uploadTypeLookup = {
    just_eat: `${BASE_API}api/daily-operations/justeats-daily-report`,
    uber_daily_order: `${BASE_API}api/daily-operations/create-ubereats-daily-report`,
    uber_daily_downtime: `${BASE_API}api/daily-operations/create-ubereats-downtime-daily-report`,
    deliveroo_daily_order: `${BASE_API}api/daily-operations/create-deliveroo-orders-daily-report`,
    deliveroo_daily_speed: `${BASE_API}api/daily-operations/create-deliveroo-speed-daily-report`,
    deliveroo_daily_open_rate: `${BASE_API}api/daily-operations/create-deliveroo-daily-open-rate`,
  };

  const handleUpload = async (info, uploadType) => {
    const { file } = info;

    setLoadingStates((prevState) => ({
      ...prevState,
      [uploadType]: true,
    }));

    const formData = new FormData();
    formData.append("file", file);
    formData.append("type", uploadType);

    try {
      const route = uploadTypeLookup[uploadType];
      if (!route) {
        console.warn(`Unsupported upload type: ${uploadType}`);
        return;
      }

      const response = await axios.post(route, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (response.status === 200) {
        setOrderUploadMessage((prevMessages) => ({
          ...prevMessages,
          [uploadType]: response.data.message || "Upload successful",
        }));
        setOrderInsertedCount((prevCounts) => ({
          ...prevCounts,
          [uploadType]: response.data.Inserted || 0,
        }));
        setOrderNotInsertedCount((prevCounts) => ({
          ...prevCounts,
          [uploadType]: response.data.NotInserted || 0,
        }));
        setOrderNotInsertedData((prevData) => ({
          ...prevData,
          [uploadType]: response.data.notInsertedData || [],
        }));
      }
    } catch (error) {
      setOrderUploadMessage((prevMessages) => ({
        ...prevMessages,
        [uploadType]: `All columns should be present in the excel file.`,
      }));
    } finally {
      setLoadingStates((prevState) => ({
        ...prevState,
        [uploadType]: false,
      }));
      clearUploadMessage();
    }
  };

  const databaseCategories = [
    {
      name: "JustEat Daily",
      type: "just_eat",
      link: "/justeats_daily_input_file.xlsx",
    },
    {
      name: "Uber Daily Order",
      type: "uber_daily_order",
      link: "/ubereats_orders_sample.xlsx",
    },
    {
      name: "Uber Daily Downtime",
      type: "uber_daily_downtime",
      link: "/ubereats_downtime.xlsx",
    },
    {
      name: "Deliveroo Daily Order",
      type: "deliveroo_daily_order",
      link: "/Deliveroo_order_sample.xlsx",
    },
    {
      name: "Deliveroo Daily Speed",
      type: "deliveroo_daily_speed",
      link: "/Deliveroo_speed_sample.xlsx",
    },
    {
      name: "Deliveroo Daily Open Rate",
      type: "deliveroo_daily_open_rate",
      link: "/deliveroo_openrate.xlsx",
    },
  ];

  const handleDrag = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const clearUploadMessage = () => {
    setTimeout(() => {
      setOrderUploadMessage({
        just_eat: "",
        uber_daily_order: "",
        uber_daily_downtime: "",
        deliveroo_daily_order: "",
        deliveroo_daily_speed: "",
        deliveroo_daily_open_rate: "",
      });
      setOrderInsertedCount({
        just_eat: 0,
        uber_daily_order: 0,
        uber_daily_downtime: 0,
        deliveroo_daily_order: 0,
        deliveroo_daily_speed: 0,
        deliveroo_daily_open_rate: 0,
      });
      setOrderNotInsertedCount({
        just_eat: 0,
        uber_daily_order: 0,
        uber_daily_downtime: 0,
        deliveroo_daily_order: 0,
        deliveroo_daily_speed: 0,
        deliveroo_daily_open_rate: 0,
      });
      setOrderNotInsertedData({
        just_eat: [],
        uber_daily_order: [],
        uber_daily_downtime: [],
        deliveroo_daily_order: [],
        deliveroo_daily_speed: [],
        deliveroo_daily_open_rate: [],
      });
    }, 5000);
  };

  return (
    <div className="container" onDrop={handleDrag} onDragOver={handleDrag}>
      <div style={{ color: "black", textAlign: "left", marginBottom: "10px" }}>
        Note: Please download the sample file, paste your data, and then upload
        it.
      </div>
      <div className="grid grid-cols-2 gap-x-8 ">
        {databaseCategories.map((category) => (
          <div key={category.name} className="upload-section">
            <h3 className="font-semibold text-lg text-left text-[#0075FC]">
              {category.name}
            </h3>
            <div className="flex items-center gap-32 mt-2 flex-wrap pb-[1rem]">
              <Upload
                customRequest={(info) => handleUpload(info, category.type)}
                showUploadList={false}
                beforeUpload={(file) => beforeUpload(file, category.type)}
              >
                <Button
                  icon={<UploadOutlined />}
                  loading={loadingStates[category.type]}
                  disabled={loadingStates[category.type]}
                >
                  {loadingStates[category.type] ? <Spin /> : "Upload Excel"}
                </Button>
              </Upload>

              <Button style={{ border: "1px solid #1890ff", color: "#1890ff" }}>
                <a
                  href={category.link}
                  download
                  style={{ color: "#1890ff", textDecoration: "none" }}
                >
                  <DownloadOutlined style={{ marginRight: "8px" }} />
                  Download Sample
                </a>
              </Button>
            </div>
            <div>
              {orderUploadMessage[category.type] && (
                <div style={{ marginTop: "1px" }}>
                  <p
                    style={{
                      color:
                        orderUploadMessage[category.type] ===
                          "You can only upload Excel files!" ||
                        orderUploadMessage[category.type] ===
                          "All columns should be present in the excel file."
                          ? "red"
                          : "black",
                    }}
                  >
                    {orderUploadMessage[category.type]}
                  </p>
                  {orderInsertedCount[category.type] >= 1 && (
                    <p style={{ color: "green" }}>
                      Inserted: {orderInsertedCount[category.type]} records
                    </p>
                  )}
                  {orderNotInsertedCount[category.type] >= 1 && (
                    <p style={{ color: "red" }}>
                      Not Inserted: {orderNotInsertedCount[category.type]}{" "}
                      records
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DailyOperations;
