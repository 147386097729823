import moment from "moment";
import React from "react";

const SingleMsg = ({ userData, item, data, i }) => {
  const is_agent =
    item.sender_id == data.id || item.sender_id.length < 5 ? 1 : 0;
  const now = moment();

  const messageTime = moment(item.createdAt);
  const difference = now.diff(messageTime, "hours");
  let dateTime = messageTime.format("hh:mm A");
  //   if (difference < 24) {
  //     dateTime =  messageTime.fromNow();
  //   }

  let msgStyle = { textAlign: "left", width: "100%" };
  if (is_agent) {
    msgStyle = {
      textAlign: "right",
      width: "100%",
    };
  }

  return (
    // <>
    <div
      className={`${
        parseInt(item.sender_id) !== data.id && item.sender_id.length > 5
          ? "col-start-1 col-end-8"
          : "col-start-6 col-end-13"
      } p-3 rounded-lg`}
      key={i}
    >
      <div
        className={`flex ${
          parseInt(item.sender_id) !== data.id && item.sender_id.length > 5
            ? "flex-row"
            : "flex-row-reverse"
        } items-center`}
      >
        <div
          className={`flex items-center justify-center h-10 w-10 rounded-full flex-shrink-0 ${
            parseInt(item.sender_id) !== data.id && item.sender_id.length > 5
              ? "bg-white"
              : "bg-indigo-500 text-white"
          }`}
        >
          {is_agent
            ? data?.name
                .split(" ")
                .map((part) => part.charAt(0))
                .join("")
            : userData?.name
                .split(" ")
                .map((part) => part.charAt(0))
                .join("")}
          {}{" "}
        </div>
        <div
          style={{ minWidth: "100px", maxWidth: "80%" }}
          className={` ${is_agent ? "mr-4" : ""}`}
        >
          {/* <div className="bg-transparent flex gap-4 items-center  ml-4">
                              <span className="font-semibold text-gray-400 text-sm">
                                {is_agent ? data.name : userData?.name}
                              </span>
                            </div> */}

          <div
            className={`relative ml-3 text-sm ${
              parseInt(item.sender_id) !== data.id && item.sender_id.length > 5
                ? "bg-white"
                : "bg-indigo-500 text-white"
            } py-2 shadow rounded-xl`}
          >
            <div className="flex flex-col  items-center px-2 py-1">
              <span className="flex-grow" style={msgStyle}>
                {item.message}
              </span>
              <span
                className={`w-full ${is_agent ? "text-right" : "text-left"} `}
              >
                <span
                  className={`${
                    is_agent ? "text-white" : "text-gray-400"
                  } text-xs`}
                >
                  {dateTime}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    // {/* </> */}
  );
};

export default SingleMsg;
