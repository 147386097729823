import React, { useState } from "react";
import { Spin, Upload, message, Button, Modal, Table } from "antd";
import { UploadOutlined, DownloadOutlined } from "@ant-design/icons";
import axios from "axios";
import { BASE_API } from "../../helper/Api";
import { toast } from "react-toastify";
import { EyeOutlined } from "@ant-design/icons";

export default function User() {
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [orderuploadMessage, setOrderUploadMessage] = useState(""); // New state for upload message
  const [orderinsertedCount, setOrderInsertedCount] = useState(0); // Add this line
  const [ordernotInsertedCount, setOrderNotInsertedCount] = useState(0); // Add this line
  const [ordernotInsertedData, setOrderNotInsertedData] = useState([]);

  const handleFileChange = (info, fileType) => {
    handleTimer();
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const handlejusteat = async ({ file, onSuccess, onError }, fileType) => {
    handleTimer();
    const isExcelFile = (file) => {
      const acceptedFormats = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "text/csv",
      ];
      return acceptedFormats.includes(file.type);
    };
    const formData = new FormData();
    formData.append("file", file);

    try {
      if (!isExcelFile(file)) {
        toast.error("Please upload a valid Excel file (CSV or XLSX format).");
        return;
      }
      setLoading(true);
      const response = await axios.post(
        BASE_API + "api/upload-justeat",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Ensure proper content type for FormData
          },
        }
      );

      // toast.success(`${fileType} file uploaded successfully.`);

      setOrderUploadMessage(response.data.message);
      setOrderInsertedCount(response.data.Inserted || 0);
      setOrderNotInsertedCount(response.data.NotInserted || 0);
      setOrderNotInsertedData(response.data.notInsertedData || 0);
    } catch (error) {
      toast.error(`${fileType} file upload failed. Please try again.`);
    } finally {
      setLoading(false);
    }
  };

  const beforeUpload = (file) => {
    return true;
  };

  const handleTimer = () => {
    // Set a timeout to hide the message after 10 seconds
    const timeoutId = setTimeout(() => {
      setOrderUploadMessage("");
      setOrderInsertedCount(0);
      setOrderNotInsertedCount(0);
      setOrderNotInsertedData([]);
    }, 10000);

    // Cleanup the timeout when the component unmounts or when the message changes
    return () => clearTimeout(timeoutId);
  };

  const handleViewDetails = () => {
    setModalVisible(true);
  };
  if (loading) {
    return (
      <div className="flex justify-center items-center">
        <Spin size="large" className="">
          <h1 className="mt-20 text-blue-900">Loading...</h1>
        </Spin>
      </div>
    );
  }

  return (
    <>
      <div className="">
        <div
          style={{ color: "black", textAlign: "left", marginBottom: "10px" }}
        >
          Note: Please download the sample file, paste your data, and then
          upload it.
        </div>
        <div className="flex justify-between px-4 lg:px-4 py-4">
          <div className="font-semibold text-lg text-left items-center text-[#0075FC] flex justify-between mt-1 w-[100%]">
            Justeat
          </div>
        </div>

        <div className="flex justify-between pb-6 mb-3">
          <div className="w-[100%] lg:px-4 px-4">
            <div className="grid grid-cols-2 mt-1 w-[100%] gap-5 min-h-[100px]">
              <Upload
                customRequest={(info) => handlejusteat(info, "order")}
                showUploadList={false}
                beforeUpload={beforeUpload}
                onChange={(info) => handleFileChange(info, "order")}
              >
                <Button
                  icon={<UploadOutlined />}
                  loading={loading}
                  disabled={loading}
                >
                  Upload Excel
                </Button>
              </Upload>

              <Button
                style={{
                  border: "1px solid #1890ff",
                  color: "#1890ff",
                  width: "229px",
                }}
              >
                <a
                  href="/justeat_samples.xlsx"
                  download
                  style={{ color: "#1890ff", textDecoration: "none" }}
                >
                  <DownloadOutlined style={{ marginRight: "8px" }} />
                  Download Sample
                </a>
              </Button>
              <div style={{ marginTop: "10px" }}>
                {/* Display the upload message */}
                {orderuploadMessage && (
                  <div>
                    <p style={{ marginBottom: "5px" }}>{orderuploadMessage}</p>
                    {orderinsertedCount > 0 && (
                      <p style={{ color: "green", marginBottom: "5px" }}>
                        Inserted: {orderinsertedCount} records
                      </p>
                    )}
                    {ordernotInsertedCount !== undefined && (
                      <div style={{ display: "flex", marginBottom: "5px" }}>
                        <p style={{ color: "red", marginRight: "10px" }}>
                          Not Inserted: {ordernotInsertedCount} records
                        </p>
                        {ordernotInsertedCount > 0 && (
                          <Button
                            type="link"
                            style={{
                              marginTop: "-6px",
                            }}
                            icon={<EyeOutlined />} // Use the EyeOutlined icon
                            onClick={handleViewDetails}
                          >
                            View Details
                          </Button>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>

              <Modal
                title="Not Inserted Details"
                visible={modalVisible}
                onCancel={() => setModalVisible(false)}
                footer={null}
              >
                {ordernotInsertedData.length > 0 ? (
                  <Table
                    dataSource={ordernotInsertedData}
                    columns={[
                      {
                        title: "Column 1",
                        dataIndex: "column1",
                        key: "column1",
                      },
                      {
                        title: "Column 2",
                        dataIndex: "column2",
                        key: "column2",
                      },
                    ]}
                    pagination={false}
                  />
                ) : (
                  <p>No details available for not inserted records.</p>
                )}
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
