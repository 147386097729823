import { createSlice } from "@reduxjs/toolkit";

const initialState = { value: 0 };

export const loaderSlice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    start_loader: (state) => {
      state.value = 1;
    },
    stop_loader: (state) => {
      state.value = 0;
    },
  },
});

export const { start_loader, stop_loader } = loaderSlice.actions;
export default loaderSlice.reducer;
