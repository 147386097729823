import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Input, Button } from "antd";
import { CallPOSTAPI } from "../../helper/Api";
import { toast } from "react-toastify";
import signinImg from "../../img/partners-top-edge.png";
import LogoImg from "../../img/honor_logo.png";

export default function Signin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    setErrorMsg("");
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const form = event.currentTarget;

    if (!email || !password) {
      setErrorMsg("Please fill in all required fields.");
    } else if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      // Start loading
      setLoading(true);

      // Perform login logic here
      // For simplicity, let's just log the email and password
      const send_obj = {
        email: email,
        password: password,
      };

      let res = await CallPOSTAPI("api/admin-login", send_obj);
      if (res?.data?.status) {
        let token = res?.data?.token;
        let user = res?.data?.user;
        localStorage.setItem("obituary_token", token);
        localStorage.setItem("gk-admin", JSON.stringify(user));
        toast.success("Login Successful");
        navigate("/restaurant");
      } else {
        toast.error(res?.data?.message);
      }

      // Stop loading
      setLoading(false);
      // Clear error message
      setErrorMsg("");
    }
  };

  return (
    <>
      <section className="h-screen">
        <div className="h-full w-full flex justify-center items-center flex-wrap text-gray-800">
          <div className="md:w-6/12 mb-12 md:mb-0 w-full">
            <img
              src={signinImg}
              className="w-full h-[100vh] md:block lg:block xl:block bg-[#039753]"
              alt="Phone image"
            />
          </div>

          <div className="md:w-5/12 border-0 p-8 shadow-2xl sm:w-[80%] sm:m-auto lg:w-5/12">
            <div className="flex justify-center">
              <img src={LogoImg} width="180px" alt="Logo" />
            </div>
            <div className="mb-6 font-bold text-2xl">
              <span className="border-b-4 border-blue-600 pb-1">Sign</span>
              &nbsp;in
            </div>
            <div>
              <form className="" onSubmit={handleSubmit} method="POST">
                <div className="mb-6">
                  <div className="my-2 font-bold">Email</div>
                  <Input
                    style={{
                      padding: "0.5rem",
                    }}
                    type="email"
                    className="text-[1rem] form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    placeholder="Enter Your User Id"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                  {errorMsg && !email && (
                    <div className="text-[0.95rem] text-[#FF0000]">
                      * Please enter email
                    </div>
                  )}
                </div>

                <div className="mb-6">
                  <div className="my-2 font-bold">Password</div>
                  <Input.Password
                    style={{
                      padding: "0.5rem",
                    }}
                    className="text-[1rem] form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    placeholder="Enter Your Password"
                    name="password"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                  />
                  {errorMsg && !password && (
                    <div className="text-[0.95rem] text-[#FF0000]">
                      * Please enter password
                    </div>
                  )}
                </div>

                <Button
                  type="primary"
                  htmlType="submit"
                  className="inline-block px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out w-[125px]"
                  loading={loading}
                >
                  {loading ? "Signing In..." : "Sign In"}
                </Button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
