import { Button, Form, Input, Modal, Radio, Select, Tabs } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import { toast } from "react-toastify";
import { post_api } from "../../helper/Api";
const { Option } = Select;
const { TabPane } = Tabs;

const EditRepModal = ({
  editModalVisible,
  setEditModalVisible,
  editRepData,
  setEditRepData,
  getAllReps,
  parentReps,
  locations,
  uniqueLocations,
  getAllLocation,
  getUniqueLocations,
}) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [selectedRole, setSelectedRole] = useState(null);

  const handleEditModalCancel = () => {
    setSelectedRole(null);
    setEditModalVisible(false);
  };

  useEffect(() => {
    if (editRepData) {
      let locationArray = [];
      // if (editRepData.role === "customer_reps" && editRepData.location) {
      locationArray = editRepData.location
        .split(",")
        .map((item) => parseInt(item));
      // }
      form.setFieldsValue({
        name: editRepData.name,
        mobile: `+${editRepData.mobile}`,
        email: editRepData.email,
        role: editRepData.role,
        location: locationArray,
        parent_reps: parseInt(editRepData.parent_reps),
      });
    }
  }, [editRepData, form]);

  const handleEditSubmit = async (values) => {
    try {
      setLoading(true);
      const locationString = values.location.toString();

      let payload = {
        ...values,
        mobile: values.mobile.startsWith("+")
          ? values.mobile.substring(1)
          : values.mobile,
        location: locationString,
      };

      const response = await post_api(
        `/reps/update-rep/${editRepData.id}`,
        payload
      );

      console.log("Edited values:", response.data);
      toast.success(response.data.message);
      setEditModalVisible(false);

      setEditRepData(null);
      getAllReps();
      getAllLocation();
      getUniqueLocations();
    } catch (err) {
      console.error(err);
      toast.error(err?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const handleRoleChange = (e) => {
    console.log("hii", e.target.value);
    setSelectedRole(e.target.value);
  };

  console.log({ editRepData });

  return (
    <Modal
      title="Edit Representative"
      visible={editModalVisible}
      onCancel={handleEditModalCancel}
      maskClosable={false}
      footer={[
        <Button key="cancel" onClick={handleEditModalCancel}>
          Cancel
        </Button>,
        <Button
          form="editRepsForm"
          key="submit"
          htmlType="submit"
          type="primary"
          loading={loading}
          onClick={handleEditSubmit}
          maskClosable={false}
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        form={form}
        id="editRepsForm"
        onFinish={handleEditSubmit}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        style={{ marginTop: "20px" }}
        initialValues={editRepData}
      >
        <Form.Item
          label="Role"
          name="role"
          rules={[{ required: true, message: "Please select a role!" }]}
        >
          <Radio.Group onChange={handleRoleChange} value={selectedRole}>
            <Radio key="manager" value="manager">
              Manager
            </Radio>
            <Radio key="customer_reps" value="customer_reps">
              Customer Reps
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Please input your name!" }]}
        >
          <Input placeholder="Enter name" />
        </Form.Item>
        <Form.Item
          name="mobile"
          label="Number"
          rules={[
            {
              required: true,
              message: "Please input your phone number!",
            },
          ]}
          className="mb-4"
        >
          <PhoneInput
            international
            defaultCountry="GB"
            placeholder="Enter phone number"
            value={form.getFieldValue("mobile") || ""}
            onChange={(value) => form.setFieldsValue({ mobile: value })}
            className="w-full p-2 rounded border border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
          />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "Please input a valid email!",
            },
          ]}
        >
          <Input placeholder="Enter email" />
        </Form.Item>

        {/* {console.log(form.getFieldValue("role"))}
        {(selectedRole === "customer_reps" ||
          editRepData?.role === "customer_reps") && (
          <>
            <Form.Item
              label="Parent Reps"
              name="parent_reps"
              rules={[{ required: true, message: "Please input parent reps!" }]}
            >
              <Select placeholder="Select a Parent Reps">
                {parentReps.map((parents) => (
                  <Option key={parents.id} value={parents.id}>
                    {parents.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </>
        )} */}

        <Form.Item
          label="Location"
          name="location"
          rules={[{ required: true, message: "Please select a location!" }]}
        >
          <Select placeholder="Select a location" mode="multiple">
            {selectedRole === "customer_reps"
              ? locations
                  .filter((location) => !uniqueLocations.includes(location.id))
                  .map((location) => (
                    <Option key={location.id} value={location.id}>
                      {`${location.name} - ${location.location}`}
                    </Option>
                  ))
              : locations.map((location) => (
                  <Option key={location.id} value={location.id}>
                    {`${location.name} - ${location.location}`}
                  </Option>
                ))}
            {/* {locations.map((location) => (
              <Option key={location.id} value={location.id}>
                {location.location}
              </Option>
            ))} */}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditRepModal;
